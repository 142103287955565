/**
 * Created by viaott on 19.07.2017.
 */
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthAdminGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate() {
		if (this.authService.isAdmin()) {
			return true;
		} else {
			this.router.navigate(['/']);
			return false;
		}
	}
}
