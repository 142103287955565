import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class NotifyService {
	constructor(
		private notificationService: NotificationsService,
		private translate: TranslateService
	) {}

	info(titleKey: string, contentKey: string, _override?: any) {
		const infoF = (title: string, content: string) => this.notificationService.info(title, content);
		this.show(titleKey, contentKey, infoF);
	}

	error(titleKey: string, contentKey: string, _override?: any) {
		const errorF = (title: string, content: string) =>
			this.notificationService.error(title, content);
		this.show(titleKey, contentKey, errorF);
	}

	success(titleKey: string, contentKey: string, _override?: any) {
		const successF = (title: string, content: string) =>
			this.notificationService.success(title, content);
		this.show(titleKey, contentKey, successF);
	}

	private show(
		titleKey: string,
		contentKey: string,
		f: (title: string, content: string, override?: any) => any
	): void {
		this.translate.get([titleKey, contentKey]).subscribe((res: string) => {
			f(res[titleKey], res[contentKey]);
		});
	}
}
