<div class="container-fluid">
	<div id="home">
		<div class="row">
			<div class="col-sm-16 col-md-16 col-lg-16 col-xl-5">
				<div class="card mb-3">
					<div class="card-body">
						<h5 class="card-title">{{ 'HOME.hello' | translate: { value: firstName } }}</h5>

						<div class="row">
							<div class="col-6" style="border-right: solid 1px #d7dde4">
								<p>{{ 'HOME.welcome' | translate }}</p>
							</div>
							<div class="col-10 pl-3">
								<p>{{ 'HOME.groups' | translate }}</p>
								<ul>
									<li *ngFor="let a of getUserGroups()">{{ a }}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="mb-3">
					<loi-order-search-quick></loi-order-search-quick>
				</div>
			</div>

			<div class="col-sm-16 col-md-16 col-lg-16 col-xl-6">
				<div class="mb-3">
					<loi-order-search-history></loi-order-search-history>
				</div>
				<div class="mb-3">
					<loi-news></loi-news>
				</div>
			</div>

			<div class="col-sm-16 col-md-16 col-lg-16 col-xl-5">
				<div class="card" style="background-color: transparent; border: none">
					<div class="row mb-3">
						<div class="col-8 col-sm-6 col-md-8 col-lg-6 col-xl-6 mb-3">
							<loi-tile [flipable]="true" style="width: 200px">
								<loi-tile-front>
									<div style="width: 100%; height: 100%; padding-top: 30px">
										<i class="fa fa-windows" aria-hidden="true"></i>
										<p>{{ 'TILES.app' | translate }}</p>
									</div>
								</loi-tile-front>
								<loi-tile-back>
									<ul class="tile-nav">
										<li><a href="http://loi-dev.lodb.org/">DEV</a></li>
										<li><a href="http://loi-qas.lodb.org/">QAS</a></li>
										<li>
											<span>
												<a href="http://loi-preprod.lodb.org/">PRE-PRD</a> /
												<a href="https://loi.lodb.org/">PRD</a>
											</span>
										</li>
									</ul>
								</loi-tile-back>
							</loi-tile>
						</div>

						<div class="col-8 col-sm-5 col-md-8 col-lg-5 col-xl-5 mb-3">
							<loi-tile [flipable]="false">
								<loi-tile-front>
									<a href="http://zdeaal10iapp154:8085/nor2_0/" style="text-decoration: none">
										<div style="width: 100%; height: 100%; padding-top: 30px">
											<i class="fa fa-windows" aria-hidden="true"></i>
											<p>{{ 'TILES.appNor' | translate }}</p>
										</div>
									</a>
								</loi-tile-front>
							</loi-tile>
						</div>

						<div class="col-8 col-sm-5 col-md-8 col-lg-5 col-xl-5 mb-3">
							<loi-tile [flipable]="false">
								<loi-tile-front>
									<a href="mailto:marvin.zielke@zeiss.com?subject=LOI%20Support'">
										<div style="width: 100%; height: 100%; padding-top: 30px">
											<i class="fa fa-envelope" aria-hidden="true"></i>
											<p>{{ 'TILES.support' | translate }}</p>
										</div>
									</a>
								</loi-tile-front>
							</loi-tile>
						</div>

						<div class="col-8 col-sm-6 col-md-8 col-lg-6 col-xl-6">
							<loi-tile [flipable]="false">
								<loi-tile-front>
									<a routerLink="/user/settings">
										<div style="width: 100%; height: 100%; padding-top: 30px">
											<i class="fa fa-cogs" aria-hidden="true"></i>
											<p>{{ 'TILES.settings' | translate }}</p>
										</div>
									</a>
								</loi-tile-front>
							</loi-tile>
						</div>

						<div class="col-8 col-sm-5 col-md-8 col-lg-5 col-xl-5">
							<loi-tile [flipable]="false">
								<loi-tile-front>
									<a routerLink="/order/search">
										<div style="width: 100%; height: 100%; padding-top: 30px">
											<i class="fa fa-search" aria-hidden="true"></i>
											<p>{{ 'TILES.search' | translate }}</p>
										</div>
									</a>
								</loi-tile-front>
							</loi-tile>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
