import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderSearchQuickComponent } from './order-search-quick/order-search-quick.component';
import { OrderSearchFormComponent } from './order-search-form/order-search-form.component';
import { OrderSearchService } from './order-search.service';
import { TranslateModule } from '@ngx-translate/core';
import { SearchDialogComponent } from './order-search-dialog/order-search-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		MatProgressBarModule,
		SharedModule,
		MatInputModule,
		FormsModule,
		MatFormFieldModule,
		MatButtonModule,
		MatRadioModule,
		MatAutocompleteModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatTooltipModule,
		MatSelectModule,
		MatDialogModule,
		TranslateModule.forChild(),
		ReactiveFormsModule,
	],
	declarations: [SearchDialogComponent, OrderSearchQuickComponent, OrderSearchFormComponent],
	exports: [SearchDialogComponent, OrderSearchQuickComponent, OrderSearchFormComponent],
	providers: [OrderSearchService],
})
export class OrderSearchModule {}
