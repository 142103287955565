import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
	private cache: { [name: string]: AsyncSubject<HttpEvent<any>> } = {};

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.method !== 'GET') {
			return next.handle(request);
		}

		const cachedResponse = this.cache[request.urlWithParams] || null;
		if (cachedResponse && request.headers.get('Force-Reload') !== 'true') {
			console.log('Returning cached version of ' + request.urlWithParams);
			return cachedResponse.pipe(delay(0));
		}

		const subject = (this.cache[request.urlWithParams] = new AsyncSubject<HttpEvent<any>>());
		next
			.handle(request)
			.pipe(
				tap({
					next: (event) => {
						if (event instanceof HttpResponse) {
							subject.next(event);
							subject.complete();
						}
					},
				})
			)
			.subscribe(); // must subscribe to actually kick off request!
		return subject;
	}
}
