import { Component } from '@angular/core';
import { OrderSearchParam } from '../entity/order-search-param';
import { IOrderSearchResult } from '../entity/order-search-result';
import { OrderSearchService } from '../order-search.service';
import { Router } from '@angular/router';
import { NotifyService } from '../../../core/notification/notify.service';
import { AuthService } from '../../../core/authentication/auth.service';

@Component({
	selector: 'loi-order-search-quick',
	templateUrl: './order-search-quick.component.html',
	styleUrls: ['./order-search-quick.component.scss'],
})
export class OrderSearchQuickComponent {
	document: string;
	showLoader = false;

	selection = 'Details';

	constructor(
		private notificationService: NotifyService,
		private orderSearchService: OrderSearchService,
		private router: Router,
		private authService: AuthService
	) {}

	search() {
		const orderSearchParam = new OrderSearchParam();
		orderSearchParam.document = this.document;
		orderSearchParam.user = this.authService.getUserInfo().eMail;
		orderSearchParam.email = 'x'; // set default to "x" -> bug in azure

		this.showLoader = true;

		const orderDetail$ = this.orderSearchService.searchOrder(orderSearchParam);
		orderDetail$.subscribe(
			(res: IOrderSearchResult[]) => {
				this.showLoader = false;
				if (res.length === 0) {
					this.notificationService.info('NOTIFICATION.orderSearch', 'NOTIFICATION.noData');
				} else if (res.length === 2) {
					if (this.selection === 'Details') {
						this.router.navigate(['/order/detail', res[0].lodb_order]);
					} else {
						this.router.navigate(['/order/czv-optic', res[0].lodb_order]);
					}
					this.notificationService.success(
						'NOTIFICATION.orderSearch',
						'NOTIFICATION.successDataOrderSearch'
					);
				} else {
					this.notificationService.success(
						'NOTIFICATION.orderSearch',
						'NOTIFICATION.successDataOrderSearchMulti'
					);
					this.router.navigate([`/order/search/${this.document}`]);
				}
			},
			() => {
				this.showLoader = false;
				this.notificationService.error('NOTIFICATION.orderSearch', 'NOTIFICATION.errorData');
			}
		);
	}
}
