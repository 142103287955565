/**
 * Created by VIAOTT on 18.07.2017.
 */
import { environment } from '../../../environments/environment';

export declare class AuthConfig {
	/**
	 * The client's id as registered with the auth server
	 */
	clientId?: string;
	/**
	 * The client's redirectUri
	 */
	redirectUri?: string;
	/**
	 * RedirectUri where the auth server
	 * redirects the user to after logging out.
	 */
	postLogoutRedirectUri?: string;
	/**
	 * Uri to the OpenID Configuration
	 */
	openidConfUrl?: string;
	/**
	 * The requested scopes
	 */
	scope?: string;
}

export const authConfig: AuthConfig = {
	clientId: environment.clientId,
	openidConfUrl: environment.openidConfUrl,
	redirectUri: window.location.origin + '/',
	postLogoutRedirectUri: window.location.origin + '/welcome',
	scope: 'openid', // email ZeissIdAgreements',
};
