import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../authentication/auth.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
	constructor(public auth: AuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const accessToken = this.auth.getAccessToken();

		let headers = req.headers;

		if (headers.has('Ignore-Header')) {
			headers = new HttpHeaders();
			req.headers.keys().forEach((element) => {
				if (element !== 'Ignore-Header' && element !== 'Force-Reload') {
					headers = headers.append(element, req.headers.get(element));
				}
			});
		} else if (
			!headers.has('Authorization') &&
			!headers.has('Ocp-Apim-Subscription-Key') &&
			!headers.has('Accept')
		) {
			headers = req.headers;
			headers = headers.append('Authorization', environment.apiAuthorization);
			headers = headers.append('Ocp-Apim-Subscription-Key', environment.ocpApimSubscriptionKey);
			headers = headers.append('Content-Type', 'application/json');
			if (accessToken) headers = headers.append('Access-Token', accessToken);
		}

		const dupReq = req.clone({ headers: headers });
		return next.handle(dupReq).pipe(
			tap({
				next: (resp) => resp,
				error: (err) => console.warn(err),
			}),
			catchError(this.handleError)
		);
	}

	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof Error) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', err.error.message);
		} else {
			console.error(`Backend returned code ${err.status}, body was:`);
			console.error(err.error);
		}
		// ...optionally return a default fallback value so app can continue (pick one)
		// which could be a default value
		// return Observable.of({my: "default value..."});
		// or simply an empty observable
		// return Observable.empty();
		return observableThrowError(() => err);
	}
}
