import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { IOrderSearchParam, OrderSearchParam } from '../entity/order-search-param';
import { StringUtil } from '../../../core/util/string.util';
import { Router, ActivatedRoute } from '@angular/router';
import { ICustomerRole } from '../../../protected/order/entity/customer-role';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { Observable } from 'rxjs/index';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { ILab } from '../../../protected/order/entity/lab';
import { AuthService } from '../../../core/authentication/auth.service';
import { ILensCode } from '../../../protected/order/entity/lensCode';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { ICountryCode } from '../../../protected/order/entity/country-code';

interface IAdvancedForm {
	customerRole: string;
	dateFrom: Date;
	dateTo: Date;
	lodbOrder: number;
	ediCode: string;
	customerNumber: number;
	commission: string;
	countryCode: string;
	labName: string;
}

interface IBaseForm {
	document: string;
	mail: string;
}

@Component({
	selector: 'loi-order-search-form',
	templateUrl: './order-search-form.component.html',
	styleUrls: ['./order-search-form.component.scss'],
})
export class OrderSearchFormComponent implements OnInit, OnChanges {
	@Output()
	searchOrder: EventEmitter<IOrderSearchParam> = new EventEmitter();

	@Output()
	searchLensCodes: EventEmitter<string> = new EventEmitter();

	@Output()
	cancelRequest: EventEmitter<void> = new EventEmitter();

	@Input()
	customerRoles: ICustomerRole[];
	filteredCustomerRoles: Observable<ICustomerRole[]>;

	@Input()
	labs: ILab[];
	filteredCustomerLabs: Observable<ILab[]>;

	@Input()
	countryCodes: ICountryCode[];
	filteredCountryCodes: Observable<ICountryCode[]>;

	@Input()
	isSearchActive: boolean;

	@Input()
	lensCodes: ILensCode[];
	lensCodeText: string;
	showLensCodeLoader = false;

	maxDateFrom: Date;
	maxDateTo: Date;
	minDateTo: Date;

	disableSubmitBtnAdvanced: boolean;
	disableSubmitBtnBase: boolean;

	advancedForm: UntypedFormGroup;
	baseForm: UntypedFormGroup;

	constructor(
		private router: Router,
		fb: UntypedFormBuilder,
		private authService: AuthService,
		private activateRoute: ActivatedRoute
	) {
		this.advancedForm = fb.group(
			{
				customerRole: [null],
				dateFrom: [null, Validators.required],
				dateTo: [null, Validators.required],
				lodbOrder: [null, [Validators.minLength(4), CustomValidators.validateNumber]], // Order Id -> if set, navigate to Detail Page
				ediCode: [null, Validators.minLength(4)],
				customerNumber: [null, [Validators.minLength(4), CustomValidators.validateNumber]],
				commission: [null, Validators.minLength(4)],
				countryCode: [null],
				labName: [null],
			},
			{
				validator: [
					CustomValidators.validateRequiredControls('customerRole', [
						'customerNumber',
						'countryCode',
					]),
				],
			}
		);

		this.baseForm = fb.group({
			document: [null, Validators.minLength(4)],
			mail: [null, CustomValidators.validateEmail],
		});

		this.filteredCustomerRoles = this.customerRole.valueChanges.pipe(
			startWith(''),
			map((role) => (role ? this.filterCustomerRole(role) : this.customerRoles.slice()))
		);

		this.filteredCustomerLabs = this.labName.valueChanges.pipe(
			startWith(''),
			map((lab) => (lab ? this.filterLab(lab) : this.labs.slice()))
		);

		this.filteredCountryCodes = this.countryCode.valueChanges.pipe(
			startWith(''),
			map((countryCode) =>
				countryCode ? this.filterCountryCode(countryCode) : this.countryCodes.slice()
			)
		);

		this.ediCode.valueChanges
			.pipe(
				startWith(''),
				debounceTime(300),
				distinctUntilChanged(),
				tap({
					next: (code: string) => {
						this.showLensCodeLoader = true;
						this.searchLensCodes.emit(code);
					},
				})
			)
			.subscribe();

		this.dateFrom.valueChanges.subscribe((date: Date) => {
			this.minDateTo = date;
		});

		this.dateTo.valueChanges.subscribe((date: Date) => {
			this.maxDateFrom = date;
		});

		this.customerNumber.valueChanges.subscribe((data: string) => {
			this.addRemoveRequiredValidator(this.customerRole, data);
		});

		this.countryCode.valueChanges.subscribe((data: string) => {
			this.addRemoveRequiredValidator(this.customerRole, data);
		});

		this.advancedForm.valueChanges.subscribe((data: IAdvancedForm) => {
			this.disableControl(this.lodbOrder, this.isAdvancedParamSet(data)); // Disabled, wenn Advanced Params gesetzt
			this.disableControl(this.dateFrom, this.isLodbOrderSet(data.lodbOrder));
			this.disableControl(this.dateTo, this.isLodbOrderSet(data.lodbOrder));
			this.disableControl(this.customerRole, this.isLodbOrderSet(data.lodbOrder));
			this.disableControl(this.ediCode, this.isLodbOrderSet(data.lodbOrder));
			this.disableControl(this.customerNumber, this.isLodbOrderSet(data.lodbOrder));
			this.disableControl(this.commission, this.isLodbOrderSet(data.lodbOrder));
			this.disableControl(this.countryCode, this.isLodbOrderSet(data.lodbOrder));
			this.disableControl(this.labName, this.isLodbOrderSet(data.lodbOrder));

			this.disableSubmitBtnAdvanced =
				!this.isLodbOrderSet(data.lodbOrder) && !this.isAdvancedParamSet(data);
		});

		this.baseForm.valueChanges.subscribe((data: IBaseForm) => {
			this.disableControl(this.document, this.isMailSet(data.mail));
			this.disableControl(this.mail, this.isDocumentSet(data.document));

			this.disableSubmitBtnBase = !this.isMailSet(data.mail) && !this.isDocumentSet(data.document);
		});
	}

	ngOnInit() {
		this.resetBase();
		this.resetAdvanced();
		setTimeout(() => {
			this.initWithSearch();
		}, 250);
	}

	initWithSearch() {
		const searchString = this.activateRoute.snapshot.paramMap.get('search');
		if (searchString) {
			this.baseForm.controls.document.setValue(searchString);
			this.searchBase();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['lensCodes'] && this.lensCodes) {
			const tmp = this.lensCodes.find((code) => code.lens_code === this.ediCode.value);
			this.lensCodeText = tmp ? tmp.text : null;
			this.showLensCodeLoader = false;
		}
	}

	get customerRole(): UntypedFormControl {
		return this.getAdvancedFormControl('customerRole');
	}

	get dateFrom(): UntypedFormControl {
		return this.getAdvancedFormControl('dateFrom');
	}

	get dateTo(): UntypedFormControl {
		return this.getAdvancedFormControl('dateTo');
	}

	get lodbOrder(): UntypedFormControl {
		return this.getAdvancedFormControl('lodbOrder');
	}

	get ediCode(): UntypedFormControl {
		return this.getAdvancedFormControl('ediCode');
	}

	get customerNumber(): UntypedFormControl {
		return this.getAdvancedFormControl('customerNumber');
	}

	get commission(): UntypedFormControl {
		return this.getAdvancedFormControl('commission');
	}

	get countryCode(): UntypedFormControl {
		return this.getAdvancedFormControl('countryCode');
	}

	get labName(): UntypedFormControl {
		return this.getAdvancedFormControl('labName');
	}

	get document(): UntypedFormControl {
		return this.getBaseFormControl('document');
	}

	get mail(): UntypedFormControl {
		return this.getBaseFormControl('mail');
	}

	private getAdvancedFormControl(controlName: string): UntypedFormControl {
		return this.advancedForm.get(controlName) as UntypedFormControl;
	}

	private getBaseFormControl(controlName: string): UntypedFormControl {
		return this.baseForm.get(controlName) as UntypedFormControl;
	}

	resetBase() {
		this.baseForm.reset();
	}

	cancel() {
		this.cancelRequest.emit();
	}

	resetAdvanced() {
		const tmpDateFrom = new Date();
		tmpDateFrom.setMonth(tmpDateFrom.getMonth() - 1);
		this.minDateTo = tmpDateFrom;
		this.maxDateTo = new Date();
		this.maxDateFrom = new Date();
		this.lensCodeText = null;

		this.advancedForm.reset();
		this.advancedForm.patchValue({
			dateFrom: tmpDateFrom,
			dateTo: new Date(),
		});
	}

	searchBase() {
		this.resetAdvanced();
		const formData: IBaseForm = this.baseForm.value;
		const orderSearchParam = new OrderSearchParam();

		orderSearchParam.document = StringUtil.isEmpty(formData.document) ? null : formData.document;
		orderSearchParam.email = StringUtil.isEmpty(formData.mail) ? 'x' : formData.mail; // set default to "x" -> bug in azure
		orderSearchParam.user = this.authService.getUserInfo().eMail;

		this.searchOrder.emit(orderSearchParam);
	}

	searchAdvanced() {
		this.resetBase();

		const formData: IAdvancedForm = this.advancedForm.value;

		if (StringUtil.isNotEmpty(formData.lodbOrder)) {
			this.router.navigate(['/order/detail', formData.lodbOrder]);
			return;
		}

		const orderSearchParam = new OrderSearchParam();
		orderSearchParam.commission = StringUtil.isEmpty(formData.commission)
			? null
			: formData.commission;
		orderSearchParam.lens_code = StringUtil.isEmpty(formData.ediCode) ? null : formData.ediCode;
		orderSearchParam.country_code = StringUtil.isEmpty(formData.countryCode)
			? null
			: this.getCountryCode(formData.countryCode);
		orderSearchParam.customer_role = StringUtil.isEmpty(formData.customerRole)
			? null
			: this.getCustomerRoleId(formData.customerRole);
		orderSearchParam.customer = StringUtil.isEmpty(formData.customerNumber)
			? null
			: formData.customerNumber;
		orderSearchParam.lab_id_rx = StringUtil.isEmpty(formData.labName)
			? null
			: this.getLabId(formData.labName);
		orderSearchParam.start_date = formData.dateFrom.toISOString();
		orderSearchParam.end_date = formData.dateTo.toISOString();
		orderSearchParam.user = this.authService.getUserInfo().eMail;
		orderSearchParam.email = 'x'; // set default to "x" -> bug in azure

		this.searchOrder.emit(orderSearchParam);
	}

	isAdvancedParamSet(data: IAdvancedForm): boolean {
		return (
			StringUtil.isNotEmpty(data.ediCode) ||
			StringUtil.isNotEmpty(data.commission) ||
			StringUtil.isNotEmpty(data.countryCode) ||
			StringUtil.isNotEmpty(data.labName) ||
			StringUtil.isNotEmpty(data.customerRole) ||
			StringUtil.isNotEmpty(data.customerNumber)
		);
	}

	isLodbOrderSet(lodbOrder: number): boolean {
		return StringUtil.isNotEmpty(lodbOrder);
	}

	isDocumentSet(document: string): boolean {
		return StringUtil.isNotEmpty(document);
	}

	isMailSet(mail: string): boolean {
		return StringUtil.isNotEmpty(mail);
	}

	private disableControl(ngControl: UntypedFormControl, condition: boolean) {
		const action = condition ? 'disable' : 'enable';
		ngControl[action]({ emitEvent: false });
	}

	private addRemoveRequiredValidator(ngControl: UntypedFormControl, data: string) {
		if (StringUtil.isNotEmpty(data)) {
			// Tho validate required if countryCode or customerNumber is entered
			this.customerRole.markAsTouched({ onlySelf: true });

			ngControl.setValidators([Validators.required]);
			ngControl.updateValueAndValidity();
		} else {
			ngControl.setValidators([]);
			ngControl.updateValueAndValidity();
		}
	}

	private getCustomerRoleId(customerRoleText: string): number {
		const customerRole = this.customerRoles.find((r: ICustomerRole) => r.text === customerRoleText);
		if (customerRole) {
			return customerRole.customer_role;
		}
		return null;
	}

	private getLabId(labText: string): number {
		const lab = this.labs.find((l: ILab) => l.loi_text === labText);
		if (lab) {
			return lab.lodb_lab;
		}
		return null;
	}

	private getCountryCode(codeText: string): string {
		const countryCode = this.countryCodes.find((c: ICountryCode) => c.text === codeText);
		if (countryCode) {
			return countryCode.country_code;
		}
		return null;
	}

	private filterCustomerRole(value: string): ICustomerRole[] {
		const filterValue = value.toLowerCase();
		return this.customerRoles.filter(
			(role) => (role.customer_role + ' - ' + role.text).toLowerCase().indexOf(filterValue) > -1
		);
	}

	private filterLab(value: string): ILab[] {
		const filterValue = value.toLowerCase();
		return this.labs.filter((lab) => lab.loi_text.toLowerCase().indexOf(filterValue) > -1);
	}

	private filterCountryCode(value: string): ICountryCode[] {
		const filterValue = value.toLowerCase();
		return this.countryCodes.filter(
			(country) => country.text.toLowerCase().indexOf(filterValue) > -1
		);
	}
}
