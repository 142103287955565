import { Component, Input, Optional } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';

@Component({
	selector: 'loi-show-error',
	template: `
		<div *ngIf="errorMessages">
			<div *ngFor="let errorMessage of errorMessages | async">
				{{ errorMessage }}
			</div>
		</div>
	`,
})
export class ShowErrorComponent {
	@Input() controlPath = '';
	@Input() displayName = '';

	constructor(
		@Optional() private ngForm: NgForm,
		@Optional() private formGroup: FormGroupDirective,
		private translate: TranslateService
	) {}

	get errorMessages(): Observable<string[]> {
		const messages: Observable<string>[] = [];

		let form: UntypedFormGroup;
		if (this.ngForm) {
			form = this.ngForm.form;
		} else {
			form = this.formGroup.form;
		}

		const control = form.get(this.controlPath);
		if (!control || !control.touched || !control.errors) {
			return null;
		}
		for (const code in control.errors) {
			if (control.errors.hasOwnProperty(code)) {
				const error = control.errors[code];
				let message: Observable<string>;
				switch (code) {
					case 'required':
						message = this.translate.get('VALIDATION.required', { value: this.displayName });
						break;
					case 'minlength':
						message = this.translate.get('VALIDATION.minLength', {
							value: this.displayName,
							value2: error.requiredLength,
						});
						break;
					case 'maxlength':
						message = this.translate.get('VALIDATION.maxLength', {
							value: this.displayName,
							value2: error.requiredLength,
						});
						break;
					case 'noNumber':
						message = this.translate.get('VALIDATION.number', { value: this.displayName });
						break;
					case 'noCharacter':
						message = this.translate.get('VALIDATION.character', { value: this.displayName });
						break;
					case 'invalidEMail':
						message = this.translate.get('VALIDATION.mail', { value: this.displayName });
						break;
				}
				messages.push(message);
			}
		}
		return forkJoin(messages);
	}
}
