import { AfterContentInit, Directive, Host, Input, Self } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs/index';

@Directive({
	selector: '[loiEnforcedInputs]',
})
export class EnforcedInputsDirective implements AfterContentInit {
	@Input()
	matAutocomplete: MatAutocomplete;

	subscription: Subscription;

	constructor(
		@Host() @Self() private readonly autoCompleteTrigger: MatAutocompleteTrigger,
		private formControl: NgControl
	) {}

	ngAfterContentInit(): void {
		if (this.matAutocomplete === undefined) {
			throw Error('valueCtrl @Input should be provided ');
		}

		setTimeout(() => {
			this.subscribeToClosingActions();
		}, 0);
	}

	private subscribeToClosingActions(): void {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.autoCompleteTrigger.panelClosingActions.subscribe(
			(e) => {
				if (!e || !e.source) {
					const selected = this.matAutocomplete.options
						.map((option) => option.value)
						// .find(option => option === this.value);
						.find((option) => option === this.formControl.value);

					if (!selected) {
						//   this.formControl.setValue(selected);
						// } else {
						this.formControl.control.setValue(null);
					}
				}
			},
			() => this.subscribeToClosingActions(),
			() => this.subscribeToClosingActions()
		);
	}
}
