import { Component } from '@angular/core';
import { AuthService } from '../../core/authentication/auth.service';

@Component({
	selector: 'loi-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
	constructor(private authService: AuthService) {}

	get firstName() {
		if (this.authService.isAuthenticated()) {
			return this.authService.getUserInfo().firstName;
		}
		return '';
	}

	getUserGroups(): string[] {
		return this.authService.getUserGroups();
	}
}
