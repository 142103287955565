<div id="accordionN" role="tablist" aria-multiselectable="true">
	<div class="card">
		<div class="card-loader-container">
			<mat-progress-bar
				*ngIf="showLoader"
				mode="indeterminate"
				[value]=""
				class="card-loader"
			></mat-progress-bar>
		</div>
		<div class="card-header">
			<ul class="nav nav-pills tab-header" role="tablist">
				<li class="nav-item">
					<a
						class="nav-link tab-header-pd active"
						data-toggle="tab"
						role="tab"
						href="#collapsePlanned"
						(click)="setTab(tabPlanned)"
					>
						<i class="fa fa-lightbulb-o" aria-hidden="true"></i>
						{{ 'NEWS.tabPlanned' | translate }}
					</a>
				</li>
				<li class="nav-item">
					<a
						class="nav-link tab-header-pd"
						data-toggle="tab"
						role="tab"
						href="#collapseDevelopment"
						(click)="setTab(tabInDevelopment)"
					>
						<i class="fa fa-cog" aria-hidden="true"></i>
						{{ 'NEWS.tabInDevelopment' | translate }}
					</a>
				</li>
				<li class="nav-item">
					<a
						class="nav-link tab-header-pd"
						data-toggle="tab"
						role="tab"
						href="#collapseRollingOut"
						(click)="setTab(tabRollingOut)"
					>
						<i class="fa fa-refresh" aria-hidden="true"></i>
						{{ 'NEWS.tabRollingOut' | translate }}
					</a>
				</li>
				<li class="nav-item">
					<a
						class="nav-link tab-header-pd"
						data-toggle="tab"
						role="tab"
						href="#collapseLaunched"
						(click)="setTab(tabLaunched)"
					>
						<i class="fa fa-globe" aria-hidden="true"></i>
						{{ 'NEWS.tabLaunched' | translate }}
					</a>
				</li>
			</ul>

			<div class="float-right tab-header-pd">
				<span class="number">{{ news[activeTab].length }}</span>
				<i
					*ngIf="activeTab === tabPlanned"
					class="fa fa-info"
					aria-hidden="true"
					matTooltip="{{ 'NEWS.infoPlanned' | translate }}"
				></i>
				<i
					*ngIf="activeTab === tabInDevelopment"
					class="fa fa-info"
					aria-hidden="true"
					matTooltip="{{ 'NEWS.infoInDevelopment' | translate }}"
				></i>
				<i
					*ngIf="activeTab === tabRollingOut"
					class="fa fa-info"
					aria-hidden="true"
					matTooltip="{{ 'NEWS.infoRollingOut' | translate }}"
				></i>
				<i
					*ngIf="activeTab === tabLaunched"
					class="fa fa-info"
					aria-hidden="true"
					matTooltip="{{ 'NEWS.infoLaunched' | translate }}"
				></i>
			</div>
		</div>

		<div class="tab-content card-body pt-0 scroll-container">
			<div id="collapsePlanned" role="tabpanel" class="tab-pane fade in active show">
				<loi-news-list [news]="news[tabPlanned]"></loi-news-list>
			</div>

			<div id="collapseDevelopment" role="tabpanel" class="tab-pane fade">
				<loi-news-list [news]="news[tabInDevelopment]"></loi-news-list>
			</div>

			<div id="collapseRollingOut" role="tabpanel" class="tab-pane fade">
				<loi-news-list [news]="news[tabRollingOut]"></loi-news-list>
			</div>

			<div id="collapseLaunched" role="tabpanel" class="tab-pane fade">
				<loi-news-list [news]="news[tabLaunched]"></loi-news-list>
			</div>
		</div>
	</div>
</div>
