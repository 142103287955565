import { Component, Input } from '@angular/core';
import { INews } from '../news';

@Component({
	selector: 'loi-news-list',
	templateUrl: './news-list.component.html',
	styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent {
	public readonly INFO = 1;
	public readonly FEATURE = 2;
	public readonly BUGFIX = 3;

	@Input()
	news: INews[];
	date: Date = new Date();

	constructor() {}
}
