import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { BookmarkService } from '../services/bookmark.service';
import { Bookmark, IBookmark } from '../model/bookmark.model';
import { of } from 'rxjs';
import * as bookmarkActions from './bookmark.action';
import * as historyActions from './history.action';
import * as notifyActions from '../../../core/notification/notify.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../core/authentication/auth.service';

@Injectable()
export class BookmarkEffects {
	httpErrors$ = createEffect(() =>
		this.actions$
			.pipe(
				ofType(
					bookmarkActions.GET_ALL_ERROR,
					bookmarkActions.CREATE_ONE_ERROR,
					bookmarkActions.UPDATE_ONE_ERROR,
					bookmarkActions.DELETE_ONE_ERROR
				)
			)
			.pipe(
				map((action: any) => action.payload),
				switchMap((error: any) => {
					return of(new notifyActions.NotifyError({ title: error.title, content: error.content }));
				})
			)
	);

	loadBookmarks$ = createEffect(() =>
		this.actions$.pipe(ofType(bookmarkActions.GET_ALL)).pipe(
			map((action: bookmarkActions.GetAll) => action.forceReload),
			switchMap((forceReload) =>
				this.bookmarkService.loadBookmarks(forceReload).pipe(
					map((data: IBookmark[]) => new bookmarkActions.GetAllSuccess(data)),
					catchError((error) =>
						of(
							new bookmarkActions.GetAllError({
								error: error,
								title: 'NOTIFICATION.searchBookmarks',
								content: 'NOTIFICATION.errorData',
							})
						)
					)
				)
			)
		)
	);

	persistBookmark$ = createEffect(() =>
		this.actions$.pipe(ofType(bookmarkActions.CREATE_ONE)).pipe(
			map((action: bookmarkActions.CreateOne) => action.payload),
			switchMap((payload) => {
				const b = new Bookmark();
				b.lodbOrder = payload.lodbOrderId;
				b.dateTime = new Date().toISOString();
				b.user = this.authService.getUserInfo().eMail;
				b.text = payload.text;

				return this.bookmarkService.persistBookmark(b).pipe(
					switchMap(() => [
						new bookmarkActions.CreateOneSuccess(b),
						new historyActions.UpdateMultiple({ lodbOrderId: b.lodbOrder, bookmarkText: b.text }),
						new notifyActions.NotifySuccess({
							title: 'NOTIFICATION.searchBookmarks',
							content: 'NOTIFICATION.successDataCreate',
						}),
					]),
					catchError((error) =>
						of(
							new bookmarkActions.CreateOneError({
								error: error,
								title: 'NOTIFICATION.searchBookmarks',
								content: 'NOTIFICATION.errorDataCreate',
							})
						)
					)
				);
			})
		)
	);

	updateBookmark$ = createEffect(() =>
		this.actions$.pipe(ofType(bookmarkActions.UPDATE_ONE)).pipe(
			map((action: bookmarkActions.UpdateOne) => action.payload),
			switchMap((payload) => {
				// TODO eigentlich sollten nur die Changes an den Server geschickt werden
				const b = Object.assign(new Bookmark(), payload.changes);
				b.lodbOrder = payload.id;
				b.dateTime = new Date().toISOString();
				b.user = this.authService.getUserInfo().eMail;

				return this.bookmarkService.updateBookmark(b).pipe(
					switchMap(() => [
						new bookmarkActions.UpdateOneSuccess(b),
						new historyActions.UpdateMultiple({ lodbOrderId: b.lodbOrder, bookmarkText: b.text }),
						new notifyActions.NotifySuccess({
							title: 'NOTIFICATION.searchBookmarks',
							content: 'NOTIFICATION.successDataUpdate',
						}),
					]),
					catchError((error) =>
						of(
							new bookmarkActions.UpdateOneError({
								error: error,
								title: 'NOTIFICATION.searchBookmarks',
								content: 'NOTIFICATION.errorDataUpdate',
							})
						)
					)
				);
			})
		)
	);

	deleteBookmark$ = createEffect(() =>
		this.actions$.pipe(ofType(bookmarkActions.DELETE_ONE)).pipe(
			map((action: bookmarkActions.DeleteOne) => action.id),
			switchMap((id) => {
				return this.bookmarkService._deleteBookmark(id).pipe(
					switchMap(() => [
						new bookmarkActions.DeleteOneSuccess(id),
						new historyActions.UpdateMultiple({ lodbOrderId: id, bookmarkText: null }),
						new notifyActions.NotifySuccess({
							title: 'NOTIFICATION.searchBookmarks',
							content: 'NOTIFICATION.successDataDelete',
						}),
					]),
					catchError((error) =>
						of(
							new bookmarkActions.DeleteOneError({
								error: error,
								title: 'NOTIFICATION.searchBookmarks',
								content: 'NOTIFICATION.errorDataDelete',
							})
						)
					)
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private bookmarkService: BookmarkService,
		private authService: AuthService
	) {}
}
