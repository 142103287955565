import { Component, OnInit } from '@angular/core';
import { ILanguage, languages, SettingsService } from '../settings/settings.service';
import { Observable } from 'rxjs';
import { ErrorService } from '../../shared/services/error.service';
import { AuthService } from '../authentication/auth.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'loi-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	selectedLanguage$: Observable<ILanguage>;
	languages = languages;

	selectedTimeZone$: Observable<string>;
	timeZones: string[];
	filteredTimeZones: string[];
	timeZoneSearchString: string;

	environmentName = environment.envName;

	constructor(
		private authService: AuthService,
		private settingsService: SettingsService,
		private errorService: ErrorService
	) {
		this.selectedLanguage$ = this.settingsService.selectedLanguage;

		this.selectedTimeZone$ = this.settingsService.selectedTimeZone;
		this.timeZones = this.settingsService.timeZones;
		this.filteredTimeZones = this.timeZones;
		this.timeZoneSearchString = '';
	}

	isError$: Observable<boolean>;

	ngOnInit() {
		this.isError$ = this.errorService.isError;
	}

	login() {
		this.authService.tryLogin();
	}

	logout() {
		this.authService.logout();
	}

	get firstName() {
		if (this.authService.isAuthenticated()) {
			return this.authService.getUserInfo().firstName;
		}
		return '';
	}

	isAuthenticated(): boolean {
		return this.authService.isAuthenticated();
	}

	isAdmin() {
		return this.authService.isAdmin();
	}

	changeLanguage(lang: ILanguage) {
		this.settingsService.changeLanguage(lang);
	}

	changeTimeZone(timeZone: string) {
		this.timeZoneSearchString = '';
		this.filteredTimeZones = this.timeZones;
		this.settingsService.changeTimeZone(timeZone);
	}

	filterTimeZones(event: any) {
		const filterValue = event.target.value.toLowerCase();
		this.filteredTimeZones = this.timeZones.filter(
			(tz) => tz.toLowerCase().indexOf(filterValue) > -1
		);
	}
}
