/**
 * Created by viaott on 19.07.2017.
 */
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ErrorService } from '../services/error.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ErrorGuard implements CanActivate {
	constructor(private errorService: ErrorService, private router: Router) {}

	canActivate(): Observable<boolean> {
		return this.errorService.isError.pipe(
			take(1),
			map((error: boolean) => {
				if (error) {
					this.router.navigate(['/error']);
					return false;
				}
				return true;
			})
		);
	}
}
