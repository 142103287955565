import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IBookmark } from '../model/bookmark.model';
import { AuthService } from '../../../core/authentication/auth.service';

@Injectable()
export class BookmarkService {
	constructor(private http: HttpClient, private authService: AuthService) {}

	public loadBookmarks(forceReload = false): Observable<IBookmark[]> {
		const mail = this.authService.getUserInfo().eMail;
		const url = environment.baseUrlEsb + mail + '/bookmark';

		return this.http.get<IBookmark[]>(url, {
			headers: new HttpHeaders().append('Force-Reload', forceReload.toString()),
		});
	}

	public persistBookmark(bookmark: IBookmark): Observable<any> {
		const url = environment.baseUrlEsb + 'bookmark';
		return this.http.post(url, bookmark);
	}

	public _deleteBookmark(lodbOrderId: number): Observable<any> {
		const mail = this.authService.getUserInfo().eMail;
		const url = environment.baseUrlEsb + mail + '/bookmark/' + lodbOrderId;
		return this.http.delete(url);
	}

	public updateBookmark(bookmark: IBookmark): Observable<any> {
		const url = environment.baseUrlEsb + 'bookmark';
		return this.http.put(url, bookmark);
	}
}
