import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable()
export class ErrorService {
	private error$ = new BehaviorSubject<boolean>(false);

	headKey$: Observable<string>;
	contentKey$: Observable<string>;

	get isError(): Observable<boolean> {
		return this.error$.asObservable();
	}

	constructor() {}

	validate(): void {
		if (this.isBrowserIE()) {
			this.error$.next(true);
			this.headKey$ = of('ERROR.browserNotSupportedHead');
			this.contentKey$ = of('ERROR.browserNotSupportedContent');
		}
	}

	private isBrowserIE(): boolean {
		return (
			/MSIE 10/i.test(navigator.userAgent) || // This is internet explorer 10
			/MSIE 9/i.test(navigator.userAgent) || // This is internet explorer 9
			/rv:11.0/i.test(navigator.userAgent) || // This is internet explorer 11
			/Edge\/\d./i.test(navigator.userAgent)
		); // This is Microsoft Edge)) {
	}
}
