import { Action } from '@ngrx/store';
import { IBookmark } from '../model/bookmark.model';

export const GET_ALL = '[Bookmark] GET_ALL';
export const GET_ALL_SUCCESS = '[Bookmark] GET_ALL_SUCCESS';
export const GET_ALL_ERROR = '[Bookmark] GET_ALL_ERROR';

export const CREATE_ONE = '[Bookmark] CREATE_ONE';
export const CREATE_ONE_SUCCESS = '[Bookmark] CREATE_ONE_SUCCESS';
export const CREATE_ONE_ERROR = '[Bookmark] CREATE_ONE_ERROR';

export const UPDATE_ONE = '[Bookmark] UPDATE_ONE';
export const UPDATE_ONE_SUCCESS = '[Bookmark] UPDATE_ONE_SUCCESS';
export const UPDATE_ONE_ERROR = '[Bookmark] UPDATE_ONE_ERROR';

export const DELETE_ONE = '[Bookmark] DELETE_ONE';
export const DELETE_ONE_SUCCESS = '[Bookmark] DELETE_ONE_SUCCESS';
export const DELETE_ONE_ERROR = '[Bookmark] DELETE_ONE_ERROR';

export class GetAll implements Action {
	readonly type = GET_ALL;

	constructor(public forceReload: boolean) {}
}

export class GetAllSuccess implements Action {
	readonly type = GET_ALL_SUCCESS;

	constructor(public bookmarks: IBookmark[]) {}
}

export class GetAllError implements Action {
	readonly type = GET_ALL_ERROR;

	constructor(public payload: any) {}
}

export class CreateOne implements Action {
	readonly type = CREATE_ONE;

	constructor(public payload: { lodbOrderId: number; text?: string }) {}
}

export class CreateOneSuccess implements Action {
	readonly type = CREATE_ONE_SUCCESS;

	constructor(public bookmark: IBookmark) {}
}

export class CreateOneError implements Action {
	readonly type = CREATE_ONE_ERROR;

	constructor(public payload: any) {}
}

export class UpdateOne implements Action {
	readonly type = UPDATE_ONE;

	constructor(public payload: { id: number; changes: Partial<IBookmark> }) {}
}

export class UpdateOneSuccess implements Action {
	readonly type = UPDATE_ONE_SUCCESS;

	constructor(public bookmark: IBookmark) {}
}

export class UpdateOneError implements Action {
	readonly type = UPDATE_ONE_ERROR;

	constructor(public payload: any) {}
}

export class DeleteOne implements Action {
	readonly type = DELETE_ONE;

	constructor(public id: number) {}
}

export class DeleteOneSuccess implements Action {
	readonly type = DELETE_ONE_SUCCESS;

	constructor(public id: number) {}
}

export class DeleteOneError implements Action {
	readonly type = DELETE_ONE_ERROR;

	constructor(public payload: any) {}
}
/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type All =
	| GetAll
	| GetAllSuccess
	| GetAllError
	| CreateOne
	| CreateOneSuccess
	| CreateOneError
	| UpdateOne
	| UpdateOneSuccess
	| UpdateOneError
	| DeleteOne
	| DeleteOneSuccess
	| DeleteOneError;
