<div id="accordionQS" role="tablist" aria-multiselectable="true">
	<div class="card">
		<div class="card-loader-container">
			<mat-progress-bar
				*ngIf="showLoader"
				mode="indeterminate"
				[value]=""
				class="card-loader"
			></mat-progress-bar>
		</div>
		<div class="card-header" style="padding: 0.5rem 1.25rem">
			<a
				data-toggle="collapse"
				data-parent="#accordionQS"
				href="#collapseQS1"
				aria-expanded="true"
				aria-controls="collapseOne"
			>
				{{ 'SEARCH-QUICK.heading' | translate }}
			</a>

			<div class="float-right">
				<i
					class="fa fa-info"
					aria-hidden="true"
					matTooltip="{{ 'SEARCH-QUICK.infoQuickSearch' | translate }}"
				></i>
			</div>
		</div>
		<div id="collapseQS1" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
			<div class="card-body">
				<form novalidate #formQuickSearch="ngForm">
					<div class="row">
						<div class="col-16 mb-2">
							<mat-radio-group class="example-radio-group" [(ngModel)]="selection" name="group">
								<mat-radio-button class="example-radio-button mr-3" value="Details" name="details">
									{{ 'SEARCH-QUICK.details' | translate }}
								</mat-radio-button>
								<mat-radio-button class="example-radio-button" value="CZV Optic" name="czvOptic">
									{{ 'SEARCH-QUICK.czvOptic' | translate }}
								</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>
					<div class="row">
						<div class="col mr-2">
							<mat-form-field class="full-width">
								<input
									matInput
									placeholder="{{ 'FIELDS.anyOrderId' | translate }}"
									name="orderId"
									[(ngModel)]="document"
									minlength="4"
								/>
								<mat-error>
									<loi-show-error text="Order ID" path="orderId"></loi-show-error>
								</mat-error>
							</mat-form-field>
						</div>
						<div style="width: 100px" class="mr-3">
							<button
								type="submit"
								mat-raised-button
								color="primary"
								class="full-width"
								(click)="search()"
								[disabled]="!formQuickSearch.valid"
							>
								{{ 'SEARCH-QUICK.btnSearch' | translate }}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
