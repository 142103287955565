import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
	selector: 'loi-bookmark-cell',
	template: `<input
		class="ag-cell-edit-input"
		#input
		type="text"
		(keydown)="onKeyDown($event)"
		[(ngModel)]="value"
	/>`,
})
export class BookmarkEditorComponent implements ICellEditorAngularComp, AfterViewInit {
	private params: any;
	public value: string;
	private cancelBeforeStart = false;

	private valueToMatch = 'x';

	@ViewChild('input', { read: ViewContainerRef, static: true }) public input;

	agInit(params: any): void {
		this.params = params;
		this.value = this.params.value;

		// only start edit if key pressed is a 'x' or any functional key
		// this.cancelBeforeStart = params.keyPress === null && params.charPress && (this.valueToMatch !== params.charPress);

		if (params.charPress && (this.value === null || this.value === '')) {
			// beginn typing if current string is empty
			this.value = params.charPress;
		} else if (params.keyPress === 8 || params.keyPress === 46) {
			this.value = '';
		}
	}

	getValue(): any {
		return this.value;
	}

	isCancelBeforeStart(): boolean {
		return this.cancelBeforeStart;
	}

	// will reject the number if it greater than 1,000,000
	// not very practical, but demonstrates the method.
	isCancelAfterEnd(): boolean {
		return this.value === null; // || this.value.length > 1;
	}

	onKeyDown(event): void {
		if (!this.isKeyPressedValue(event)) {
			if (event.preventDefault) {
				event.preventDefault();
			}
		}
	}

	// dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
	ngAfterViewInit() {
		setTimeout(() => {
			this.input.element.nativeElement.focus();
		});
	}

	private getCharCodeFromEvent(event): any {
		event = event || window.event;
		return typeof event.which === 'undefined' ? event.keyCode : event.which;
	}

	private isCharValid(charStr): boolean {
		return charStr === this.valueToMatch; // !!/\d/.test(charStr);
	}

	private isKeyPressedValue(_event): boolean {
		return true; // this.isCharValid(charStr);
	}
}
