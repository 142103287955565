import { Component, OnInit } from '@angular/core';
import { NewsService } from './news.service';
import { INews } from './news';
import { ILanguage, SettingsService } from '../../core/settings/settings.service';

@Component({
	selector: 'loi-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
	readonly tabPlanned = 0;
	readonly tabInDevelopment = 1;
	readonly tabRollingOut = 2;
	readonly tabLaunched = 3;
	activeTab = this.tabPlanned;

	showLoader = false;

	news: INews[][];

	constructor(private newsService: NewsService, private settingsService: SettingsService) {
		this.news = [];
		this.news[this.tabPlanned] = [];
		this.news[this.tabInDevelopment] = [];
		this.news[this.tabRollingOut] = [];
		this.news[this.tabLaunched] = [];
	}

	ngOnInit() {
		this.settingsService.selectedLanguage.subscribe((lang) => {
			this.loadData(lang);
		});
	}

	loadData(lang?: ILanguage) {
		this.showLoader = true;

		const news$ = this.newsService.loadNewsByLang(lang);
		news$.subscribe(
			(res) => {
				this.showLoader = false;
				const allNews = res;
				this.news[this.tabPlanned] = this.newsService.filterNewsByGroupType(
					allNews,
					this.tabPlanned + 1
				);
				this.news[this.tabInDevelopment] = this.newsService.filterNewsByGroupType(
					allNews,
					this.tabInDevelopment + 1
				);
				this.news[this.tabRollingOut] = this.newsService.filterNewsByGroupType(
					allNews,
					this.tabRollingOut + 1
				);
				this.news[this.tabLaunched] = this.newsService.filterNewsByGroupType(
					allNews,
					this.tabLaunched + 1
				);

				// if (res.hasOwnProperty('error')) {
				//   this.notificationService.info("NOTIFICATION.news", "NOTIFICATION.noData");
				// } else {
				//   this.notificationService.success("NOTIFICATION.news", "NOTIFICATION.successData");
				// }
			},
			() => {
				this.showLoader = false;
				// this.notificationService.error("NOTIFICATION.news", "NOTIFICATION.errorData");
			}
		);
	}

	setTab(tab: number) {
		this.activeTab = tab;
	}
}
