import { throwError as observableThrowError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import * as dayjs from 'dayjs';

export class CommonUtil {
	public static handleError(error: HttpErrorResponse | any) {
		let errMsg: string;
		if (error instanceof HttpErrorResponse) {
			const body = error.error || '';
			const err = body.error || JSON.stringify(body);
			errMsg = `${error.status} - ${error.statusText || ''} ${err} - URL: ${error.url}`;
		} else {
			errMsg = error.message ? error.message : error.toString();
		}
		return observableThrowError(errMsg);
	}

	/**
	 * Formats a date according to locale rules.
	 *
	 * @param {string} value
	 * @param {string} format
	 * @param {string} local
	 * @returns {string}
	 */
	public static formatDate(value: string, format = 'L LTS') {
		const retVal = dayjs(value).format(format);
		return retVal.toLowerCase().includes('invalid') ? value : retVal;
	}

	/**
	 * Formats a number as text
	 *
	 * @param {string} value
	 * @param {string} format -> is a string which has a following format:
	 * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
	 * @param {string} local
	 * @returns {string}
	 */
	public static formatNumber(value: string, format: string, local = 'en-US') {
		return new DecimalPipe(local).transform(value, format);
	}

	/**
	 * Sort ISO Date Strings  Descanding way
	 *
	 * @param {string} aIsoDate
	 * @param {string} bIsoDate
	 * @returns {number}
	 */
	public static sortDateDes(aIsoDate: string, bIsoDate: string) {
		const da = new Date(aIsoDate).getTime();
		const db = new Date(bIsoDate).getTime();
		if (da > db) {
			return -1;
		}
		if (da < db) {
			return 1;
		}
		return 0;
	}

	/**
	 * Returns a Interface property as String
	 *
	 * [\s\S] to be able to match on multilines because Typescript convert (object: Interface) => {object.code;} to a multiline function
	 *
	 * @param {(object: any) => void} propertyFunc
	 * @returns {string}
	 */
	public static interfacePropertyToString(propertyFunc: (object: any) => any): string {
		const chaine = propertyFunc.toString();
		const arr = chaine.split('.');
		return arr[1];
	}
}
