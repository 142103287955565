import {
	AbstractControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';

export class CustomValidators extends Validators {
	static validateEmail(control: AbstractControl): ValidationErrors | null {
		const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
		if (!control.value || control.value === '' || re.test(control.value)) {
			return null;
		} else {
			return { invalidEMail: true };
		}
	}

	static validateNumber(control: AbstractControl): ValidationErrors | null {
		if (!control.value || control.value === '' || !Number.isNaN(+control.value)) {
			return null;
		} else {
			return { noNumber: true };
		}
	}

	static validateCharacters(control: AbstractControl): ValidationErrors | null {
		const re = /^[a-zA-Z]*$/;
		if (!control.value || control.value === '' || re.test(control.value)) {
			return null;
		} else {
			return { noCharacter: true };
		}
	}

	static validateRequiredControls(
		controlPath: string,
		requiredControlsPath: string[]
	): ValidatorFn {
		return (form: UntypedFormGroup): ValidationErrors | null => {
			const trigger = form.get(controlPath);
			if (!trigger || !trigger.value) {
				return null;
			}

			let isValueSet = false;
			requiredControlsPath.forEach((name) => {
				const control = form.get(name);
				isValueSet = control && control.value ? true : isValueSet;
			});

			return isValueSet ? null : { controlsRequired: { controlNames: requiredControlsPath } };
		};
	}
}
