import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../../shared/services/error.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'loi-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
	headKey$: Observable<string>;
	contentKey$: Observable<string>;

	constructor(private errorService: ErrorService) {}

	ngOnInit() {
		this.headKey$ = this.errorService.headKey$;
		this.contentKey$ = this.errorService.contentKey$;
	}
}
