import * as HistoryActions from './history.action';
import { IHistory } from '../model/history.model';

export interface State {
	ids: string[];
	entries: IHistory[];
	loading: boolean;
}

const initialState: State = {
	ids: [],
	entries: [],
	loading: false,
};

export function reducer(state = initialState, action: HistoryActions.All): State {
	switch (action.type) {
		case HistoryActions.GET_ALL:
		case HistoryActions.CREATE_ONE:
			return { ...state, loading: true };

		case HistoryActions.GET_ALL_ERROR:
		case HistoryActions.CREATE_ONE_ERROR:
			return { ...state, loading: false };

		case HistoryActions.GET_ALL_SUCCESS:
			return {
				ids: action.history.map((h) => h.datetime),
				entries: action.history,
				loading: false,
			};
		case HistoryActions.CREATE_ONE_SUCCESS:
			return {
				ids: [...state.ids, action.history.datetime],
				entries: [...state.entries, action.history],
				loading: false,
			};

		case HistoryActions.UPDATE_MULTIPLE:
			return {
				ids: [...state.ids],
				entries: state.entries.map((item) => {
					return item.lodb_order === action.payload.lodbOrderId
						? Object.assign({}, item, { isBookmark: action.payload.bookmarkText })
						: item;
				}),
				loading: false,
			};
		default:
			return { ...state };
	}
}

export const getIds = (state: State) => state.ids;
export const getEntries = (state: State) => state.entries;
export const getLoading = (state: State) => state.loading;
