export interface IOrderSearchResult {
	lodb_order: number;
	document: number;
	side: string;
	lens_code: string;
	lens_text: string;
	customer: number;
	country_code: number;
	lab_id_rx: string;
	date: string;
}

export class OrderSearchResult implements IOrderSearchResult {
	lodb_order: number;
	document: number;
	side: string;
	lens_code: string;
	lens_text: string;
	customer: number;
	country_code: number;
	lab_id_rx: string;
	date: string;

	constructor(data?: IOrderSearchResult) {
		this.lodb_order = (data && data.lodb_order) || null;
		this.document = (data && data.document) || null;
		this.side = (data && data.side) || null;
		this.lens_code = (data && data.lens_code) || null;
		this.lens_text = (data && data.lens_text) || null;
		this.customer = (data && data.customer) || null;
		this.country_code = (data && data.country_code) || null;
		this.lab_id_rx = (data && data.lab_id_rx) || null;
		this.date = (data && data.date) || null;
	}
}
