export interface IOrderSearchParam {
	document: string;
	commission: string;
	lens_code: string;
	country_code: string;
	start_date: string;
	end_date: string;
	email: string;
	customer: number;
	customer_role: number;
	lab_id_rx: number;
	user: string;
}

export class OrderSearchParam implements IOrderSearchParam {
	document: string;
	commission: string;
	lens_code: string;
	country_code: string;
	start_date: string;
	end_date: string;
	email: string;
	customer: number;
	customer_role: number;
	lab_id_rx: number;
	user: string;

	constructor(data?: IOrderSearchParam) {
		this.document = (data && data.document) || null;
		this.commission = (data && data.commission) || null;
		this.lens_code = (data && data.lens_code) || null;
		this.country_code = (data && data.country_code) || null;
		this.start_date = (data && data.start_date) || null;
		this.end_date = (data && data.end_date) || null;
		this.email = (data && data.email) || null;
		this.customer = (data && data.customer) || null;
		this.customer_role = (data && data.customer_role) || null;
		this.lab_id_rx = (data && data.lab_id_rx) || null;
		this.user = (data && data.user) || null;
	}
}
