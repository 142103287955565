import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app.routes';
import { WelcomeComponent } from './public/welcome/welcome.component';
import { RouterModule } from '@angular/router';
import { HomeModule } from './protected/home/home.module';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { metaReducers, reducers } from './index';
import { ErrorComponent } from './public/error/error.component';
import { TimingInterceptor } from './core/http/timing.interceptor';
import { HttpsRequestInterceptor } from './core/http/https-request.interceptor';
import { EffectsModule } from '@ngrx/effects';
import { NotifyEffects } from './core/notification/notify.effect';
import { AuthService } from './core/authentication/auth.service';
import { CookieModule } from 'ngx-cookie';
import { CacheInterceptor } from './core/http/cache.interceptor';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { SimpleNotificationsModule } from 'angular2-notifications';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

export function authServiceFactory(authService: AuthService): Function {
	return async () => await authService.tryLogin();
}

@NgModule({
	imports: [
		NoopAnimationsModule,
		RouterModule,
		FormsModule,
		HttpClientModule,
		CoreModule,
		SharedModule,
		AppRoutingModule,
		HomeModule,
		SimpleNotificationsModule.forRoot(),
		MatProgressBarModule,
		MatCheckboxModule,
		MatMomentDateModule, // make sure that the MatMomentDateModule is only imported once -> issues with setLocale(..) in lazy loaded module
		CookieModule.withOptions(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([NotifyEffects]),
		!environment.production ? StoreDevtoolsModule.instrument() : [],
	],
	declarations: [AppComponent, WelcomeComponent, ErrorComponent],
	providers: [
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpsRequestInterceptor,
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: authServiceFactory,
			deps: [AuthService],
			multi: true,
		}, // Provider for APP_INITIALIZER

		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		// { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
