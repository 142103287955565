import { Injectable } from '@angular/core';

@Injectable()
export class ProgressService {
	show: boolean;

	constructor() {}

	start() {
		this.show = true;
	}

	done() {
		this.show = false;
	}
}
