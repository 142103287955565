<div id="accordionS" role="tablist" aria-multiselectable="true">
	<div class="card">
		<div class="card-header" style="padding: 0.5rem 1.25rem" role="tab" id="headingOne">
			<a
				data-toggle="collapse"
				href="#collapseOne"
				aria-expanded="true"
				aria-controls="collapseOne"
			>
				{{ 'SEARCH-FORM.search' | translate }}
			</a>
		</div>
		<div
			id="collapseOne"
			data-parent="#accordionS"
			class="collapse show"
			role="tabpanel"
			aria-labelledby="headingOne"
		>
			<form novalidate #formSearch="ngForm" [formGroup]="baseForm">
				<div class="card-body">
					<div class="row row-grid">
						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<mat-form-field class="full-width">
								<input
									matInput
									placeholder="{{ 'FIELDS.anyOrderId' | translate }}"
									formControlName="document"
								/>
								<mat-error>
									<loi-show-error
										[displayName]="'FIELDS.anyOrderId' | translate"
										controlPath="document"
									></loi-show-error>
								</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<mat-form-field class="full-width">
								<input
									matInput
									placeholder="{{ 'FIELDS.mail' | translate }}"
									formControlName="mail"
								/>
								<mat-error>
									<loi-show-error
										[displayName]="'FIELDS.mail' | translate"
										controlPath="mail"
									></loi-show-error>
								</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 offset-xl-4 mb-2">
							<div class="row">
								<div class="col-8 col-md-8 mb-2">
									<button
										type="submit"
										mat-raised-button
										class="full-width"
										color="primary"
										(click)="searchBase()"
										[disabled]="disableSubmitBtnBase || !baseForm.valid"
									>
										{{ 'SEARCH-FORM.btnSearch' | translate }}
									</button>
								</div>
								<div class="col-8 col-md-8">
									<button type="submit" mat-raised-button class="full-width" (click)="resetBase()">
										{{ 'SEARCH-FORM.btnClear' | translate }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
	<div class="card">
		<div class="card-header" style="padding: 0.5rem 1.25rem" role="tab" id="headingTwo">
			<a
				style="margin-right: 20px"
				class="collapsed"
				data-toggle="collapse"
				href="#collapseTwo"
				aria-expanded="false"
				aria-controls="collapseTwo"
			>
				{{ 'SEARCH-FORM.advanced' | translate }}
			</a>
			<mat-error style="display: inline-block">
				<div
					*ngIf="
						advancedForm.errors &&
						advancedForm.errors['controlsRequired'] &&
						(advancedForm.touched || advancedForm.dirty)
					"
				>
					<span [translate]="'VALIDATION.requiredControls'" style="padding-right: 5px"></span>
					<span
						*ngFor="
							let name of advancedForm.errors['controlsRequired'].controlNames;
							last as isLast
						"
					>
						<span [translate]="'FIELDS.' + name"></span>
						<span *ngIf="!isLast">, </span>
					</span>
				</div>
			</mat-error>
		</div>
		<div
			id="collapseTwo"
			data-parent="#accordionS"
			class="collapse"
			role="tabpanel"
			aria-labelledby="headingTwo"
		>
			<form novalidate #formAdvanced="ngForm" [formGroup]="advancedForm">
				<div class="card-body">
					<div class="row">
						<div class="col-md-8 col-lg-5 col-xl-8 mb-2">
							<mat-form-field class="full-width">
								<input
									matInput
									placeholder="{{ 'FIELDS.ediCode' | translate }}"
									formControlName="ediCode"
									[matAutocomplete]="autoEdiCode"
								/>
								<mat-spinner matSuffix [diameter]="18" *ngIf="showLensCodeLoader"></mat-spinner>
								<mat-hint *ngIf="lensCodeText">{{ lensCodeText }}</mat-hint>
								<mat-autocomplete #autoEdiCode="matAutocomplete">
									<mat-option *ngFor="let code of lensCodes" [value]="code.lens_code">
										{{ code.text }}
									</mat-option>
								</mat-autocomplete>
								<mat-error>
									<loi-show-error
										[displayName]="'FIELDS.ediCode' | translate"
										controlPath="ediCode"
									></loi-show-error>
								</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<mat-form-field class="full-width">
								<input
									matInput
									placeholder="{{ 'FIELDS.customerNumber' | translate }}"
									formControlName="customerNumber"
								/>
								<mat-error>
									<loi-show-error
										[displayName]="'FIELDS.customerNumber' | translate"
										controlPath="customerNumber"
									></loi-show-error>
								</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<div class="input-group input-group-sm">
								<mat-form-field class="full-width">
									<input
										type="text"
										matInput
										loiEnforcedInputs
										placeholder="{{ 'FIELDS.customerRole' | translate }}"
										formControlName="customerRole"
										[matAutocomplete]="autoCr"
									/>
									<mat-autocomplete #autoCr="matAutocomplete">
										<mat-option
											*ngFor="let role of filteredCustomerRoles | async"
											[value]="role.text"
										>
											{{ role.customer_role }} - {{ role.text }}
										</mat-option>
									</mat-autocomplete>
									<mat-error>
										<loi-show-error
											[displayName]="'FIELDS.customerRole' | translate"
											controlPath="customerRole"
										></loi-show-error>
									</mat-error>
								</mat-form-field>
							</div>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<mat-form-field class="full-width">
								<input
									matInput
									placeholder="{{ 'FIELDS.commission' | translate }}"
									formControlName="commission"
								/>
								<mat-error>
									<loi-show-error
										[displayName]="'FIELDS.commission' | translate"
										controlPath="commission"
									></loi-show-error>
								</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<mat-form-field class="full-width">
								<input
									matInput
									name="lodbOrder"
									formControlName="lodbOrder"
									placeholder="{{ 'FIELDS.lodbOrder' | translate }}"
								/>
								<mat-error>
									<loi-show-error
										[displayName]="'FIELDS.lodbOrder' | translate"
										controlPath="lodbOrder"
									></loi-show-error>
								</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<mat-form-field class="full-width">
								<input
									type="text"
									matInput
									loiEnforcedInputs
									placeholder="{{ 'FIELDS.countryCode' | translate }}"
									formControlName="countryCode"
									[matAutocomplete]="autoCountry"
								/>
								<mat-autocomplete #autoCountry="matAutocomplete">
									<mat-option
										*ngFor="let country of filteredCountryCodes | async"
										[value]="country.text"
									>
										{{ country.text }}
									</mat-option>
								</mat-autocomplete>

								<mat-error>
									<loi-show-error
										[displayName]="'FIELDS.countryCode' | translate"
										controlPath="countryCode"
									></loi-show-error>
								</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<div class="input-group input-group-sm">
								<mat-form-field class="full-width">
									<input
										type="text"
										matInput
										loiEnforcedInputs
										placeholder="{{ 'FIELDS.lab' | translate }}"
										formControlName="labName"
										[matAutocomplete]="autoLab"
									/>
									<mat-autocomplete #autoLab="matAutocomplete">
										<mat-option
											*ngFor="let lab of filteredCustomerLabs | async"
											[value]="lab.loi_text"
										>
											{{ lab.loi_text }}
										</mat-option>
									</mat-autocomplete>
									<mat-error>
										<loi-show-error
											[displayName]="'FIELDS.lab' | translate"
											controlPath="labName"
										></loi-show-error>
									</mat-error>
								</mat-form-field>
							</div>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<mat-form-field class="full-width">
								<input
									matInput
									name="orderDateStart"
									formControlName="dateFrom"
									placeholder="{{ 'FIELDS.orderDateFrom' | translate }}"
									[max]="maxDateFrom"
									[matDatepicker]="datepickerFrom"
								/>
								<mat-datepicker-toggle matSuffix [for]="datepickerFrom"></mat-datepicker-toggle>
								<mat-datepicker #datepickerFrom></mat-datepicker>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<mat-form-field class="full-width">
								<input
									matInput
									name="orderDateEnd"
									formControlName="dateTo"
									placeholder="{{ 'FIELDS.orderDateTo' | translate }}"
									[min]="minDateTo"
									[max]="maxDateTo"
									[matDatepicker]="datepickerTo"
								/>
								<mat-datepicker-toggle matSuffix [for]="datepickerTo"></mat-datepicker-toggle>
								<mat-datepicker #datepickerTo></mat-datepicker>
							</mat-form-field>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<div class="row">
								<div class="col-8 col-md-8 mb-2">
									<button
										type="submit"
										mat-raised-button
										class="full-width"
										color="primary"
										(click)="searchAdvanced()"
										[disabled]="disableSubmitBtnAdvanced || !advancedForm.valid"
									>
										{{ 'SEARCH-FORM.btnSearch' | translate }}
									</button>
								</div>
								<div class="col-8 col-md-8">
									<button
										type="submit"
										mat-raised-button
										class="full-width"
										(click)="resetAdvanced()"
									>
										{{ 'SEARCH-FORM.btnClear' | translate }}
									</button>
								</div>
							</div>
						</div>

						<div class="col-md-8 col-lg-5 col-xl-4 mb-2">
							<div class="row">
								<div class="col-8 col-md-8 mb-2">
									<button
										type="submit"
										mat-raised-button
										class="full-width"
										(click)="cancel()"
										[disabled]="!isSearchActive"
									>
										{{ 'SEARCH-FORM.btnCancel' | translate }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
