import { Component } from '@angular/core';

@Component({
	selector: 'loi-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
	constructor() {}
}
