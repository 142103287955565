<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
	<div class="d-flex">
		<a [routerLink]="['/home']" routerLinkActive="active" class="navbar-brand">
			<img alt="Brand" src="assets/img/logo.png" width="28" height="28" />
		</a>
		<span class="navbar-text text-truncate d-none d-sm-block d-xl-none">LOI App</span>
		<span class="navbar-text text-truncate d-none d-xl-block">Lens Order Information App</span>
		<span
			class="navbar-text text-truncate environment d-none d-sm-block d-md-none"
			*ngIf="environmentName !== 'prod'"
			>{{ environmentName }}</span
		>
		<span
			class="navbar-text text-truncate environment d-none d-md-block"
			*ngIf="environmentName !== 'prod'"
			>{{ 'MENU.environment' | translate }}: {{ environmentName }}</span
		>
	</div>

	<button
		class="navbar-toggler"
		type="button"
		data-toggle="collapse"
		data-target="#collapsingNavbar"
	>
		<span class="navbar-toggler-icon"></span>
	</button>

	<div class="collapse navbar-collapse" id="collapsingNavbar">
		<ul class="navbar-nav ml-auto">
			<li class="nav-item" *ngIf="isAuthenticated() && (isError$ | async) !== true">
				<a class="nav-link" routerLink="/home" routerLinkActive="active">
					{{ 'MENU.home' | translate }}
				</a>
			</li>

			<li class="nav-item" *ngIf="isAuthenticated() && (isError$ | async) !== true">
				<a class="nav-link" routerLink="/order/search">
					{{ 'MENU.search' | translate }}
				</a>
			</li>

			<!-- <li class="nav-item" *ngIf="isAdmin() && !(isError$ | async)">
				<a class="nav-link" routerLink="/order/chart-overview/">
					{{ 'MENU.scm' | translate }}
				</a>
			</li> -->

			<li class="separator" *ngIf="isAuthenticated() && (isError$ | async) !== true"></li>

			<li class="nav-item dropdown">
				<a class="nav-link dropdown-toggle" id="navbarDropdownMenuLinkLang" data-toggle="dropdown">
					{{ (selectedLanguage$ | async).name }}
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkLang">
					<span *ngFor="let lang of languages">
						<a class="dropdown-item" (click)="changeLanguage(lang)">{{ lang.name }}</a>
					</span>
				</div>
			</li>

			<li class="nav-item dropdown">
				<a
					class="nav-link dropdown-toggle"
					id="navbarDropdownMenuLinkTimeZone"
					data-toggle="dropdown"
				>
					{{ selectedTimeZone$ | async }}
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkTimeZone">
					<input
						placeholder="Search"
						class="dropdown-item search"
						[(ngModel)]="timeZoneSearchString"
						(keyup)="filterTimeZones($event)"
						autofocus
					/>

					<div style="max-height: 200px; overflow-y: auto">
						<span *ngFor="let tz of filteredTimeZones">
							<a class="dropdown-item" (click)="changeTimeZone(tz)">{{ tz }}</a>
						</span>
					</div>
				</div>
			</li>

			<li class="separator" *ngIf="(isError$ | async) !== true"></li>

			<li class="nav-item" *ngIf="!isAuthenticated() && (isError$ | async) !== true">
				<a class="nav-link" (click)="login()"
					><i class="fa fa-sign-in" aria-hidden="true"></i>{{ 'MENU.signIn' | translate }}</a
				>
			</li>

			<li class="nav-item dropdown" *ngIf="isAuthenticated() && (isError$ | async) !== true">
				<a
					href="#"
					class="nav-link dropdown-toggle"
					id="navbarDropdownUserLink"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<i class="fa fa-user" aria-hidden="true"></i>{{ firstName }}
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownUserLink">
					<a class="dropdown-item" routerLink="/user/settings" routerLinkActive="active"
						><i class="fa fa-cogs" aria-hidden="true"></i>Settings</a
					>
					<div class="dropdown-divider"></div>
					<a class="dropdown-item" (click)="logout()"
						><i class="fa fa-sign-out" aria-hidden="true"></i>Sign out</a
					>
				</div>
			</li>
		</ul>
	</div>
</nav>
