import { UrlParts } from '../authentication/auth.model';

export class UrlUtil {
	public static getUrlParams(_url: string): UrlParts {
		let url = decodeURIComponent(_url);

		// transform hash-fragment to query param (workaround for ZEISS ID issue)
		url = url.replace('#', '&');

		const questionMarkPosition = url.indexOf('?');
		if (questionMarkPosition === -1) {
			return {};
		}
		if (questionMarkPosition > -1) {
			url = url.substr(url.indexOf('?') + 1);
		}
		return UrlUtil.parseQueryString(url);
	}

	public static parseQueryString(queryString: string): UrlParts {
		const data = {};

		if (queryString === null) {
			return data;
		}
		const pairs = queryString.split('&');

		for (let i = 0; i < pairs.length; i++) {
			const pair = pairs[i];
			const separatorIndex = pair.indexOf('=');
			let escapedKey, escapedValue;

			if (separatorIndex === -1) {
				escapedKey = pair;
				escapedValue = null;
			} else {
				escapedKey = pair.substr(0, separatorIndex);
				escapedValue = pair.substr(separatorIndex + 1);
			}

			const key = decodeURIComponent(escapedKey);
			const value = decodeURIComponent(escapedValue);
			data[key] = value;
		}
		return data;
	}
}
