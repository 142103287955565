import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TileComponent } from './tile.component';
import { MatRippleModule } from '@angular/material/core';
import { TileFrontComponent } from './tile-front.component';
import { TileBackComponent } from './tile-back.component';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatRippleModule],
	exports: [TileComponent, TileFrontComponent, TileBackComponent],
	declarations: [TileComponent, TileFrontComponent, TileBackComponent],
	providers: [],
})
export class TileModule {}
