import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonUtil } from '../../core/util/common.util';
import { environment } from '../../../environments/environment';
import { INews } from './news';
import { ILanguage } from '../../core/settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class NewsService {
	private _news: INews[];
	public newsLang$: Observable<INews[]>;

	constructor(private http: HttpClient) {}

	public loadNewsByLang(lang: ILanguage): Observable<INews[]> {
		if (this._news) {
			this.newsLang$ = of(this.filterByLang(lang.id, this._news));
		} else {
			this.newsLang$ = this.loadNews().pipe(
				mergeMap((news: INews[]) => {
					this._news = news;
					return of(this.filterByLang(lang.id, this._news));
				})
			);
		}
		return this.newsLang$;
	}

	public filterNewsByGroupType(news: INews[], groupType: number): INews[] {
		return news.filter((n) => n.group_type === groupType);
	}

	loadNews(): Observable<INews[]> {
		const url = environment.baseUrlEsb + 'news';
		return this.http.get<INews[]>(url);
	}

	private filterByLang(langId: number, news: INews[]) {
		return news
			.filter((n) => n.language === langId)
			.sort((a: INews, b: INews) => CommonUtil.sortDateDes(a.datetime, b.datetime));
	}
}
