<div class="container" style="margin-bottom: 60px">
	<h3>{{ 'WELCOME.heading' | translate }}</h3>
	<p>{{ 'WELCOME.textSignInToContinue' | translate }}</p>

	<a href="#"><i class="fa fa-sign-in" aria-hidden="true"></i>{{ 'WELCOME.signIn' | translate }}</a>
	<br />
	<br />
	<p>{{ 'WELCOME.textNoAccount' | translate }}</p>
	<a
		href="mailto:marvin.zielke@zeiss.com?subject=LOI App: Request for new Login&body=Please enter your CZNet User.%0D%0DCZNet User:"
	>
		{{ 'WELCOME.eMail' | translate }}
		<i class="fa fa-envelope-o" aria-hidden="true" style="margin-left: 8px"></i>
	</a>
</div>
