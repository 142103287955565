import * as BookmarkActions from './bookmark.action';
import { IBookmark } from '../model/bookmark.model';

export interface State {
	ids: number[];
	entries: IBookmark[];
	map: { [id: number]: IBookmark };
	loading: boolean;
}

const initialState: State = {
	ids: [],
	entries: [],
	map: {},
	loading: false,
};

export function reducer(state = initialState, action: BookmarkActions.All): State {
	switch (action.type) {
		case BookmarkActions.GET_ALL:
		case BookmarkActions.CREATE_ONE:
		case BookmarkActions.UPDATE_ONE:
		case BookmarkActions.DELETE_ONE:
			return { ...state, loading: true };

		case BookmarkActions.GET_ALL_ERROR:
		case BookmarkActions.CREATE_ONE_ERROR:
		case BookmarkActions.UPDATE_ONE_ERROR:
		case BookmarkActions.DELETE_ONE_ERROR:
			return { ...state, loading: false };

		case BookmarkActions.GET_ALL_SUCCESS:
			return {
				ids: action.bookmarks.map((b) => b.lodbOrder),
				entries: action.bookmarks,
				map: action.bookmarks.reduce((map: { [id: number]: IBookmark }, item: IBookmark) => {
					return Object.assign(map, { [item.lodbOrder]: item });
				}, {}),
				loading: false,
			};
		case BookmarkActions.CREATE_ONE_SUCCESS: {
			if (state.ids.indexOf(action.bookmark.lodbOrder) > -1) {
				return state;
			}
			return {
				ids: [...state.ids, action.bookmark.lodbOrder],
				entries: [...state.entries, action.bookmark],
				map: Object.assign({}, state.map, { [action.bookmark.lodbOrder]: action.bookmark }),
				loading: false,
			};
		}
		case BookmarkActions.UPDATE_ONE_SUCCESS:
			return {
				ids: [...state.ids],
				entries: state.entries.map((item) => {
					return item.lodbOrder === action.bookmark.lodbOrder
						? Object.assign({}, item, action.bookmark)
						: item;
				}),
				map: Object.assign({}, state.map, { [action.bookmark.lodbOrder]: action.bookmark }),
				loading: false,
			};
		case BookmarkActions.DELETE_ONE_SUCCESS: {
			const newMap = Object.assign({}, state.map);
			delete newMap[action.id];

			return {
				ids: state.ids.filter((id) => id !== action.id),
				entries: state.entries.filter((b) => b.lodbOrder !== action.id),
				map: newMap,
				loading: false,
			};
		}

		default:
			return { ...state };
	}
}

export const getIds = (state: State) => state.ids;
export const getEntries = (state: State) => state.entries;
export const getMapEntries = (state: State) => state.map;
export const getLoading = (state: State) => state.loading;
