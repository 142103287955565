import { Component, Input, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'loi-tile',
	templateUrl: './tile.component.html',
	styleUrls: ['./tile.component.scss'],
	animations: [
		trigger('flipState', [
			state(
				'active',
				style({
					transform: 'rotateY(179.9deg)',
				})
			),
			state(
				'inactive',
				style({
					transform: 'rotateY(0)',
				})
			),
			transition('active => inactive', animate('400ms ease-out')),
			transition('inactive => active', animate('400ms ease-in')),
		]),
	],
	encapsulation: ViewEncapsulation.None,
})
export class TileComponent {
	@Input() flipable = true;

	flip = 'inactive';
	constructor() {}

	enterTile() {
		if (this.flipable) {
			this.flip = 'active';
		}
	}

	leaveTile() {
		if (this.flipable) {
			this.flip = 'inactive';
		}
	}
}
