// prettier-ignore
// this file is automatically generated by package-info.ts script
export const Build = {
  appVersion: `3.0.0`,
  branch: `MASTER`,
  lastCommit: `58e97f1`,
  lastCommitUrl: `https://zeissgroup.visualstudio.com/LensOrderDB/_git/Zeiss.Vision.Digital.Portal.Frontend/commit/58e97f14827b34fc1b042389ed59cba9004f4db1`,
  lastCommitHash: `58e97f14827b34fc1b042389ed59cba9004f4db1`,
  lastCommitTime: `2024-06-03T12:03:04Z`,
  lastCommitMessage: `ci: added devcontainer.json for remote container clone`,
  lastCommitAuthor: `Aaron Czichon`
};
