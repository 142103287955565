import { HomeComponent } from './home.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OrderSearchHistoryModule } from '../../feature/order-search-history/order-search-history.module';
import { TileModule } from '../../feature/tile/tile.module';
import { OrderSearchModule } from '../../feature/order-search/order-search.module';
import { TranslateModule } from '@ngx-translate/core';
import { NewsModule } from '../../feature/news/news.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		MatProgressBarModule,
		OrderSearchHistoryModule,
		TileModule,
		OrderSearchModule,
		TranslateModule.forChild(),
		NewsModule,
	],
	declarations: [HomeComponent],
	exports: [HomeComponent],
})
export class HomeModule {}
