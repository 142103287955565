import { Injectable } from '@angular/core';
import { authConfig, AuthConfig } from './auth.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlUtil } from '../util/url.util';
import { CookieService } from 'ngx-cookie';
import {
	Dict,
	OpenidConfiguration,
	ParsedIdToken,
	UrlParts,
	ValidationParams,
	WebKey,
	WebKeyResponse,
	ZeissIdBase,
	ZeissIdCustomFields,
} from './auth.model';
import { ILanguage, SettingsService } from '../settings/settings.service';

import rs from 'jsrsasign';

@Injectable()
export class AuthService {
	private config: AuthConfig;

	private userInfo: ZeissIdBase;
	private userGroups: string[];
	private customFields: ZeissIdCustomFields;
	private authenticated = false;

	constructor(
		private http: HttpClient,
		private cookieService: CookieService,
		private settingsService: SettingsService
	) {
		this.config = authConfig;
	}

	public getOpenidConfiguration(): Promise<OpenidConfiguration> {
		return this.http
			.get<OpenidConfiguration>(this.config.openidConfUrl, {
				headers: new HttpHeaders().append('Ignore-Header', 'true').append('Force-Reload', 'true'),
			})
			.toPromise();
	}

	public getWebKey(jwksUri: string): Promise<WebKeyResponse> {
		return this.http
			.get<WebKeyResponse>(jwksUri, {
				headers: new HttpHeaders().append('Ignore-Header', 'true').append('Force-Reload', 'true'),
			})
			.toPromise();
	}

	public async tryLogin(): Promise<void> {
		let valid = false;

		const parts: UrlParts = UrlUtil.getUrlParams(window.location.href);
		const savedIdToken = this.getIdToken();

		try {
			if (savedIdToken) {
				await this.processIdToken(savedIdToken);
				valid = true;
			} else if (parts.hasOwnProperty('id_token') && parts.id_token) {
				await this.processIdToken(parts.id_token);
				valid = true;
				this.setSession(parts.id_token, parts.code);
				window.history.replaceState(null, null, window.location.href.split('?')[0]);
			}
		} catch (err) {
			console.log(err);
		}

		if (!valid) {
			this.removeSession();
			await this.login(); // wenn token nicht vorhanden oder invalide -> redirect to login page
		}
		return Promise.resolve();
	}

	public async processIdToken(idToken: string): Promise<void> {
		const parsedIdToken = rs.KJUR.jws.JWS.parse(idToken) as any as ParsedIdToken;
		const openidConfig: OpenidConfiguration = await this.getOpenidConfiguration();
		const allowedAlgorithms: string[] = openidConfig.id_token_signing_alg_values_supported;
		const issuer: string = openidConfig.issuer;
		const jwks: WebKeyResponse = await this.getWebKey(openidConfig.jwks_uri);

		const vp: ValidationParams = {
			idToken: idToken,
			idTokenParsed: parsedIdToken,
			jwks: jwks,
			allowedAlgorithms: allowedAlgorithms,
			issuer: issuer,
		};

		if (!this.isNonceValid(parsedIdToken.payloadObj.nonce)) {
			return Promise.reject('Wrong nonce');
		}

		try {
			await this.validateJwtSignature(vp);
			console.log('parsedIdToken:');
			console.log(parsedIdToken);
			this.userInfo = JSON.parse(parsedIdToken.payloadObj.ZeissIdBase);
			this.customFields = JSON.parse(parsedIdToken.payloadObj.ZeissIdCustomFields);
			this.setUserGroups(this.customFields);
			this.authenticated = true;

			this.settingsService.setDefaultLang();
			setTimeout(() => {
				console.debug(
					`setting user language: ZEISS ID says ${
						this.userInfo.language
					}, setting lang to ${JSON.stringify(this.getUserLang())}`
				);
				this.settingsService.changeLanguage(this.getUserLang());
			}, 100);
			return Promise.resolve();
		} catch (err) {
			return Promise.reject(err);
		}
	}

	private async validateJwtSignature(parmas: ValidationParams): Promise<void> {
		const gracePeriod = 60;
		let validSignature = false;

		const parsedIdToken: ParsedIdToken = parmas.idTokenParsed;
		const kid: string = parsedIdToken.headerObj.kid;
		const key: WebKey = parmas.jwks.keys.find((k: WebKey) => k.kid === kid);

		if (key != null) {
			const publicKey = rs.KEYUTIL.getKey(key);
			const validationOptions = {
				iss: [parmas.issuer], // array of acceptable issuer names
				alg: parmas.allowedAlgorithms, // array of acceptable signature algorithm names
				gracePeriod: gracePeriod, // acceptable time difference between signer and verifier in seconds
				// verifyAt: rs.KJUR.jws.IntDate.getNow()
			};
			// console.log(new Date(parsedIdToken.payloadObj.exp * 1000));
			validSignature = rs.KJUR.jws.JWS.verifyJWT(
				parmas.idToken, // string of JSON Web Token(JWT) to verify
				publicKey as any, // string of public key, certificate or key object to verify
				validationOptions // associative array of acceptable fields
			);
		}
		if (!validSignature) {
			// if token is invalid, remove cookie
			return Promise.reject('Signature not valid');
		}
		return Promise.resolve();
	}

	private isNonceValid(nonceInJwt: string): boolean {
		const savedNonce = this.cookieService.get('nonce');
		return savedNonce === nonceInJwt;
	}

	private getRedirectUrl(): string {
		return document.location.href.split('?')[0];
	}

	private setSession(idToken: string, accessToken: string) {
		this.cookieService.put('id_token', idToken);
		this.cookieService.put('access_token', accessToken);
	}

	private setNonce(nonce: string) {
		this.cookieService.put('nonce', nonce);
	}

	private removeSession() {
		this.cookieService.remove('id_token');
		this.cookieService.remove('access_token');
		this.cookieService.remove('nonce');
	}

	public getAccessToken(): string {
		return this.cookieService.get('access_token');
	}

	private getIdToken(): string {
		return this.cookieService.get('id_token');
	}

	public async login() {
		const config: OpenidConfiguration = await this.getOpenidConfiguration();
		const nonce = await this.createAndSaveNonce();
		const uri = encodeURIComponent(this.getRedirectUrl().replace('/#/', '/'));

		const loginUrl: string = this.extendQueryString(config.authorization_endpoint, {
			nonce: nonce,
			redirect_uri: uri, // this.config.redirectUri,
			response_type: 'code+id_token',
			client_id: this.config.clientId,
			scope: this.config.scope,
			response_mode: 'query', // "query", "fragment", "form_post
		});
		document.location.href = loginUrl;
	}

	private async createAndSaveNonce(): Promise<string> {
		const nonce = await this.createNonce();
		this.setNonce(nonce);
		return Promise.resolve(nonce);
	}

	private async createNonce(): Promise<string> {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < 40; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		return Promise.resolve(text);
	}

	public async logout() {
		const config: OpenidConfiguration = await this.getOpenidConfiguration();
		this.removeSession();
		window.location.href = this.extendQueryString(config.end_session_endpoint, {
			post_logout_redirect_uri: this.config.postLogoutRedirectUri,
		});
	}

	private extendQueryString(url: string, data: Partial<Dict>): string {
		let tmp = url;
		Object.entries(data).forEach((value, _index) => {
			const attr = '&' + value[0] + '=' + value[1];
			tmp += attr;
		});
		console.log(tmp);
		return tmp;
	}

	isAdmin(): boolean {
		return this.customFields && this.customFields.vis_loi_ddp;
	}

	isExpert(): boolean {
		return this.customFields && this.customFields.vis_loi_expert;
	}

	getUserInfo(): ZeissIdBase {
		return this.userInfo;
	}

	getUserLang(): ILanguage {
		return this.settingsService.getLanguageByName(this.userInfo.language);
	}

	setUserGroups(customFields: ZeissIdCustomFields): void {
		this.userGroups = [];
		for (const key of Object.keys(customFields)) {
			if (customFields[key]) {
				this.userGroups.push(key);
			}
		}
	}

	getUserGroups(): string[] {
		return this.userGroups;
	}

	isAuthenticated(): boolean {
		return this.authenticated;
	}
}
