import { Action } from '@ngrx/store';
import { IHistory } from '../model/history.model';

export const GET_ALL = '[History] GET_ALL';
export const GET_ALL_SUCCESS = '[History] GET_ALL_SUCCESS';
export const GET_ALL_ERROR = '[History] GET_ALL_ERROR';

export const CREATE_ONE = '[History] CREATE_ONE';
export const CREATE_ONE_SUCCESS = '[History] CREATE_ONE_SUCCESS';
export const CREATE_ONE_ERROR = '[History] CREATE_ONE_ERROR';

export const UPDATE_MULTIPLE = '[History] UPDATE_MULTIPLE';

export class GetAll implements Action {
	readonly type = GET_ALL;

	constructor(public forceReload: boolean) {}
}

export class GetAllSuccess implements Action {
	readonly type = GET_ALL_SUCCESS;

	constructor(public history: IHistory[]) {}
}

export class GetAllError implements Action {
	readonly type = GET_ALL_ERROR;

	constructor(public payload: any) {}
}

export class CreateOne implements Action {
	readonly type = CREATE_ONE;

	constructor(public id: number) {}
}

export class CreateOneSuccess implements Action {
	readonly type = CREATE_ONE_SUCCESS;

	constructor(public history: IHistory) {}
}

export class CreateOneError implements Action {
	readonly type = CREATE_ONE_ERROR;

	constructor(public payload: any) {}
}

export class UpdateMultiple implements Action {
	readonly type = UPDATE_MULTIPLE;

	constructor(public payload: { lodbOrderId: number; bookmarkText: string }) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type All =
	| GetAll
	| GetAllSuccess
	| GetAllError
	| CreateOne
	| CreateOneSuccess
	| CreateOneError
	| UpdateMultiple;
