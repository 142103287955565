import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSearchHistoryComponent } from './order-search-history.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HistoryService } from './services/history.service';
import { TranslateModule } from '@ngx-translate/core';
import { HistoryTableComponent } from './search-history-table/history-table.component';
import { BookmarkTableComponent } from './search-bookmark-table/bookmark-table.component';
import { BookmarkEditorComponent } from './bookmark-editor.component';
import { FormsModule } from '@angular/forms';
import { BookmarkService } from './services/bookmark.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/index';
import { BookmarkEffects } from './store/bookmark.effect';
import { HistoryEffects } from './store/history.effect';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		AgGridModule.withComponents([BookmarkEditorComponent]),
		MatProgressBarModule,
		MatTooltipModule,
		TranslateModule.forChild(),

		/**
		 * StoreModule.forFeature is used for composing state
		 * from feature modules. These modules can be loaded
		 * eagerly or lazily and will be dynamically added to
		 * the existing state.
		 */
		StoreModule.forFeature('orderSearchHistory', reducers),

		/**
		 * Effects.forFeature is used to register effects
		 * from feature modules. Effects can be loaded
		 * eagerly or lazily and will be started immediately.
		 *
		 * All Effects will only be instantiated once regardless of
		 * whether they are registered once or multiple times.
		 */
		EffectsModule.forFeature([BookmarkEffects, HistoryEffects]),
	],
	declarations: [
		OrderSearchHistoryComponent,
		HistoryTableComponent,
		BookmarkTableComponent,
		BookmarkEditorComponent,
	],
	providers: [HistoryService, BookmarkService],
	exports: [OrderSearchHistoryComponent],
})
export class OrderSearchHistoryModule {}
