import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './authentication/auth.guard';
import { ProgressService } from './progressbar/progress.service';
import { SettingsService } from './settings/settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { NotifyService } from './notification/notify.service';
import { AuthService } from './authentication/auth.service';
import { AuthAdminGuard } from './authentication/auth-admin.guard';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [CommonModule, RouterModule, FormsModule, TranslateModule.forChild()],
	exports: [NavbarComponent],
	declarations: [NavbarComponent],
	providers: [
		AuthService,
		AuthGuard,
		AuthAdminGuard,
		ProgressService,
		SettingsService,
		NotifyService,
	],
})
export class CoreModule {}
