import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchTableComponent } from '../search-table.component';
import { ColDef, GridOptions } from 'ag-grid';
import { IBookmark } from '../model/bookmark.model';
import { SettingsService } from '../../../core/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'loi-bookmark-table',
	templateUrl: './bookmark-table.component.html',
})
export class BookmarkTableComponent extends SearchTableComponent implements OnInit {
	@Output()
	updateBookmark: EventEmitter<{ lodbOrderId: number; text: string }> = new EventEmitter();
	@Output()
	deleteBookmark: EventEmitter<number> = new EventEmitter();

	_rowData: IBookmark[] = [];
	get rowData(): IBookmark[] {
		return this._rowData;
	}
	@Input()
	set rowData(values: IBookmark[]) {
		this._rowData = values.map((x) => Object.assign({}, x)); // create a copy because data$ is immutable
	}

	private readonly HEADER_KEYS = {
		date: 'COLUMN-NAMES.date',
		lodbId: 'COLUMN-NAMES.lodbId',
		text: 'COLUMN-NAMES.text',
	};

	columnDefs: ColDef[];
	getRowNodeId;

	constructor(
		private translate: TranslateService,
		private settingsService: SettingsService,
		private router: Router
	) {
		super();
	}

	ngOnInit() {
		this.gridOptions = <GridOptions>{};
		this.gridOptions.onGridReady = () => {
			setTimeout(() => {
				this.sizeCols();
			}, 1000);
		};

		this.settingsService.selectedLanguage.subscribe(() => {
			this.translate.get(Object.values(this.HEADER_KEYS)).subscribe((translations: object) => {
				this.setColumnDefs(translations);
			});
		});
		this.settingsService.selectedTimeZone.subscribe(() => {
			if (this.gridOptions.api) {
				this.gridOptions.api.redrawRows();
			}
		});

		this.getRowNodeId = (data: IBookmark) => {
			return data.lodbOrder;
		};
	}

	setColumnDefs(translations: object): void {
		const context = this;
		this.columnDefs = [
			{
				headerName: translations[this.HEADER_KEYS.date],
				field: 'dateTime',
				width: 200,
				sort: 'desc',
				valueFormatter: this.dateFormatter,
			},
			{
				headerName: translations[this.HEADER_KEYS.lodbId],
				field: 'lodbOrder',
				width: 130,
				cellRenderer: this.urlCellRenderer,
				enableRowGroup: true,
				onCellClicked: (params) => {
					context.router.navigate(['/order/detail', params.value]);
				},
			},
			{
				headerName: translations[this.HEADER_KEYS.text],
				field: 'text',
				width: 200,
				enableRowGroup: false,
				cellRenderer: this.cellRenderer,
				editable: true,
				onCellValueChanged: (event) => {
					this.cellValueChanged(event);
				},
			},
		];
	}

	cellValueChanged(event) {
		if (!event || event.oldValue === event.newValue) {
			return;
		}
		const lodbOrderId = event.data.lodbOrder;
		const text = event.newValue === '' ? 'x' : event.newValue;

		this.updateBookmark.emit({ lodbOrderId: lodbOrderId, text: text });
	}

	cellRenderer(params) {
		const element = document.createElement('span');
		if (params.value !== 'x') {
			element.innerHTML = params.value;
		}
		return element;
	}

	deleteData(): void {
		const bookmarks: IBookmark[] = this.gridOptions.api.getSelectedRows();
		if (bookmarks.length > 0) {
			this.deleteBookmark.emit(bookmarks[0].lodbOrder);
		}
	}
}
