export const environment = {
	production: false,
	envName: 'dev',

	// Authentication
	clientId: '9f8b4fe5-71e1-4ae5-8f40-a0b220fba7a6',
	openidConfUrl:
		'https://id-ip-stage.zeiss.com/CD/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdAADOnlySignIn',

	// REST API:
	baseUrlEsb: 'https://esb-dev.zeiss.com/public/api/experience/vis/orderdetails/dev/',
	ocpApimSubscriptionKey: '14eadb65d14c44b8b457d3df7da4ba70',
	apiAuthorization:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTg5NzA3Mzk0IiwiY3JlYXRlIjp0cnVlfQ.5Aq8GUbYQPFvmj44wZjw_d1a45EbN8e6UKOIzlTe_kg',

	// BLOB Access:
	blobAccessKey:
		'?sv=2021-06-08&ss=b&srt=o&sp=r&se=2025-02-01T16:08:37Z&st=2022-11-03T08:08:37Z&spr=https&sig=lSff9PIPLBLwG%2BLMMOTIbD7YF0Hh0U3mU8HP62sUYtk%3D',
	lmtDetailUrl: 'http://adeerl01ilx004.zeiss.org/lmt-client/#/detail?ediCode=',
};
