import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import timezoneList from 'timezones-list';
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export interface ILanguage {
	name: string;
	id: number;
	dayjsCode?: string;
}

export interface ITimeZone {
	name: string;
	value: string;
}

export const languages: ILanguage[] = [
	{ name: 'en-US', id: 10, dayjsCode: 'en' },
	{ name: 'en-GB', id: 11 },
	{ name: 'DE', id: 20 },
	{ name: 'FR', id: 30 },
	{ name: 'IT', id: 40 },
	{ name: 'CN', id: 50, dayjsCode: 'zh' },
];

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	timeZones = timezoneList.map((tz) => tz.name);

	selectedLanguage = new BehaviorSubject(languages[0]);
	selectedTimeZone: BehaviorSubject<string>;

	constructor(private translate: TranslateService, private dateAdapter: DateAdapter<any>) {
		this.initTime();
	}

	initTime() {
		for (const key in languages) {
			require(`dayjs/locale/${(languages[key].dayjsCode ?? languages[key].name).toLowerCase()}`);
		}

		const timeZone = dayjs.tz.guess();
		dayjs.tz.setDefault(timeZone);

		this.selectedTimeZone = new BehaviorSubject(timeZone);
	}

	setDefaultLang() {
		let defaultLang = languages.find((x) => x.id === 10).name;
		if (defaultLang.startsWith('en-')) {
			defaultLang = 'en';
		}
		this.translate.setDefaultLang(defaultLang);
	}

	changeTimeZone(timeZone: string) {
		// set time zone
		dayjs.tz.setDefault(timeZone);
		this.selectedTimeZone.next(timeZone);
	}

	changeLanguage(lang: ILanguage) {
		if (!lang) {
			return;
		}

		const language: string = lang.name.toString().toLowerCase();

		// set language for the translation
		this.translate.use(language.startsWith('en-') ? 'en' : language);

		// set language in moment js
		const local = language === 'cn' ? 'zh_cn' : language;
		dayjs.locale(local);

		// set the language of the datepicker at runtime using the setLocale method of the DateAdapter:
		this.dateAdapter.setLocale(language);

		this.selectedLanguage.next(lang);
	}

	getLanguageByName(languageName: string): ILanguage {
		let retVal = languages.find((l) => l.name.toLowerCase() === languageName.toLowerCase());

		if (!retVal) {
			languageName = languageName.split('-')[0];
			retVal = languages.find((l) => l.name.toLowerCase() === languageName.toLowerCase());
		}
		return retVal ? retVal : languages.find((l) => l.id === 10);
	}
}
