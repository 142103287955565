import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotifyService } from './notify.service';
import * as notifyActions from './notify.action';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class NotifyEffects {
	notifySuccess$ = createEffect(
		() =>
			this.actions$.pipe(ofType(notifyActions.NOTIFY_SUCCESS)).pipe(
				map((action: notifyActions.NotifySuccess) => action.payload),
				switchMap((obj: { title: string; content: string }) => {
					return of(this.notificationService.success(obj.title, obj.content));
				})
			),
		{ dispatch: false }
	);

	notifyError$ = createEffect(
		() =>
			this.actions$.pipe(ofType(notifyActions.NOTIFY_ERROR)).pipe(
				map((action: notifyActions.NotifyError) => action.payload),
				switchMap((obj: { title: string; content: string }) => {
					return of(this.notificationService.error(obj.title, obj.content));
				})
			),
		{ dispatch: false }
	);

	constructor(private actions$: Actions, private notificationService: NotifyService) {}
}
