import { Component } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { ProgressService } from './core/progressbar/progress.service';
import { NotifyService } from './core/notification/notify.service';
import { ErrorService } from './shared/services/error.service';
import { SettingsService } from './core/settings/settings.service';
import { filter } from 'rxjs/operators';
import { NotificationAnimationType, Options } from 'angular2-notifications';
import { LicenseManager } from 'ag-grid-enterprise';
import { Build } from '../environments/build';

@Component({
	selector: 'loi-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	options: Options = {
		position: ['bottom', 'right'],
		timeOut: 5000,
		showProgressBar: true,
		pauseOnHover: true,
		clickToClose: true,
		maxStack: 4,
		lastOnBottom: true,
		animate: NotificationAnimationType.FromRight,
	};

	environmentName = environment.envName;
	build = Build;

	constructor(
		private router: Router,
		private settingsService: SettingsService,
		private notificationService: NotifyService,
		public progress: ProgressService,
		private errorService: ErrorService
	) {
		LicenseManager.setLicenseKey(
			'Carl_Zeiss_AG_MultiApp_2Devs12_July_2018__MTUzMTM1MDAwMDAwMA==91c0026163f6e0bb70abc6d230c820ce'
		);

		this.settingsService.setDefaultLang();

		router.events
			.pipe(filter((event) => event instanceof NavigationStart))
			.subscribe((event: NavigationStart) => {
				if (event.url.includes('/#/')) {
					this.router.navigate([event.url.replace('/#/', '/')]);
				}
				this.progress.start();
			});
		router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.progress.done();
		});
		router.events
			.pipe(filter((event) => event instanceof NavigationError))
			.subscribe((event: NavigationError) => {
				this.notificationService.error('NOTIFICATION.error', 'NOTIFICATION.errorURL');
				console.error("Can't access URL " + event.url, 'because of ' + event.error);
				this.progress.done();
			});
		this.errorService.validate(); // e.g. if browser is supported
	}
}
