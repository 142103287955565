import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TimingInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const started = Date.now();
		return next.handle(req).pipe(
			tap({
				next: (event) => {
					if (event instanceof HttpResponse) {
						const elapsed = Date.now() - started;
						console.log(`${req.method} Request for ${req.urlWithParams} took ${elapsed} ms.`);
					}
				},
			})
		);
	}
}
