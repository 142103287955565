<ag-grid-angular
	style="width: 100%; height: 210px"
	class="ag-theme-material"
	[rowData]="rowData"
	[columnDefs]="columnDefs"
	[gridOptions]="gridOptions"
	[deltaRowDataMode]="true"
	[getRowNodeId]="getRowNodeId"
	toolPanelSuppressSideButtons
	toolPanelSuppressValues
	toolPanelSuppressPivots
	toolPanelSuppressPivotMode
	enableColResize
	enableSorting
	enableFilter
	animateRows
	suppressRowClickSelection
	[rowHeight]="30"
	[headerHeight]="35"
>
</ag-grid-angular>
