import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DatePipe, LOCAL_LANG } from './date.pipe';
import { BehaviorSubject } from 'rxjs';
import { ILanguage } from '../../core/settings/settings.service';

@NgModule({
	declarations: [DatePipe],
	imports: [CommonModule],
	exports: [DatePipe],
	providers: [DatePipe],
})
export class DateModule {
	static forRoot(lang: BehaviorSubject<ILanguage>): ModuleWithProviders<DateModule> {
		return {
			ngModule: DateModule,
			providers: [{ provide: LOCAL_LANG, useValue: lang }],
		};
	}
}
