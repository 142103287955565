import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ShowErrorComponent } from './components/show-error/show-error.component';
import { XmlPipe } from './pipes/xml.pipe';
import { ErrorService } from './services/error.service';
import { ErrorGuard } from './guards/error.guard';
import { EnforcedInputsDirective } from './directives/enforced-inputs.directive';
import { DateModule } from './pipes/date.module';
import { ILanguage, languages, SettingsService } from '../core/settings/settings.service';
import { BehaviorSubject } from 'rxjs';
import localeEnUS from '@angular/common/locales/en-001';
import localeEnGB from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeZh from '@angular/common/locales/zh';

registerLocaleData(localeEnGB, 'en-GB');
registerLocaleData(localeEnUS, 'en-US');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeZh, 'zh');

let currentLang = new BehaviorSubject<ILanguage>(languages[0]);

@NgModule({
	imports: [CommonModule, DateModule.forRoot(currentLang)],
	declarations: [ShowErrorComponent, XmlPipe, EnforcedInputsDirective],
	providers: [ErrorService, ErrorGuard, XmlPipe],
	exports: [ShowErrorComponent, EnforcedInputsDirective, DateModule],
})
export class SharedModule {
	constructor(private settingsService: SettingsService) {
		this.settingsService.selectedLanguage.subscribe({
			next: (lang) => currentLang.next(lang ?? languages[0]),
		});
	}
}
