import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError as observableThrowError } from 'rxjs';
import { IOrderSearchParam } from './entity/order-search-param';
import { IOrderSearchResult, OrderSearchResult } from './entity/order-search-result';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class OrderSearchService {
	private searchResult: IOrderSearchResult[];
	public isSearchStatusActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private http: HttpClient) {
		this.clearLastSearchResult();
	}

	public searchOrder(searchParam: IOrderSearchParam): Observable<IOrderSearchResult[]> {
		const url = environment.baseUrlEsb + 'search';

		this.isSearchStatusActive$.next(true);

		return this.http.post(url, searchParam).pipe(
			map((data: any) => {
				this.isSearchStatusActive$.next(false);

				const tmp = data.Table1 === null ? [] : data.Table1;
				this.searchResult = tmp.map((data2) => {
					return new OrderSearchResult(data2);
				});
				return this.searchResult;
			}),
			catchError((error) => {
				this.isSearchStatusActive$.next(false);
				return observableThrowError(() => error);
			})
		);
	}

	getLastSearchResult(): Observable<IOrderSearchResult[]> {
		return of(this.searchResult);
	}

	clearLastSearchResult(): void {
		this.searchResult = [];
	}
}
