import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IHistory } from '../model/history.model';
import { AuthService } from '../../../core/authentication/auth.service';

@Injectable()
export class HistoryService {
	constructor(private http: HttpClient, private authService: AuthService) {}

	public loadHistory(forceReload = false): Observable<IHistory[]> {
		const mail = this.authService.getUserInfo().eMail;
		const url = environment.baseUrlEsb + mail + '/searchHistory';

		return this.http.get<IHistory[]>(url, {
			headers: new HttpHeaders().append('Force-Reload', forceReload.toString()),
		});
	}

	public persistSearch(searchHistoryEntry: IHistory): Observable<any> {
		const url = environment.baseUrlEsb + 'searchHistory';

		return this.http.post<IHistory[]>(url, searchHistoryEntry);
	}
}
