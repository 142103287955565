import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHistory } from '../model/history.model';
import { ColDef, GridOptions } from 'ag-grid';
import { SearchTableComponent } from '../search-table.component';
import { BookmarkEditorComponent } from '../bookmark-editor.component';
import { StringUtil } from '../../../core/util/string.util';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { Router } from '@angular/router';

@Component({
	selector: 'loi-history-table',
	templateUrl: './history-table.component.html',
})
export class HistoryTableComponent extends SearchTableComponent implements OnInit {
	@Output()
	createBookmark: EventEmitter<{ lodbOrderId: number; text: string }> = new EventEmitter();
	@Output()
	updateBookmark: EventEmitter<{ lodbOrderId: number; text: string }> = new EventEmitter();
	@Output()
	deleteBookmark: EventEmitter<number> = new EventEmitter();

	_rowData: IHistory[] = [];
	get rowData(): IHistory[] {
		return this._rowData;
	}
	@Input()
	set rowData(values: IHistory[]) {
		this._rowData = values.map((x) => Object.assign({}, x)); // create a copy because data$ is immutable
	}

	private readonly HEADER_KEYS = {
		date: 'COLUMN-NAMES.date',
		lodbId: 'COLUMN-NAMES.lodbId',
		bookmark: 'COLUMN-NAMES.bookmark',
	};

	columnDefs: ColDef[];
	getRowNodeId;

	constructor(
		private translate: TranslateService,
		private settingsService: SettingsService,
		private router: Router
	) {
		super();
	}

	ngOnInit() {
		this.gridOptions = <GridOptions>{};
		this.gridOptions.onGridReady = () => {
			setTimeout(() => {
				this.sizeCols();
			}, 1500);
		};

		// listen to settingsService.selectedLanguage (translate.onLangChange is no Subject -> no initial value)
		this.settingsService.selectedLanguage.subscribe(() => {
			this.translate.get(Object.values(this.HEADER_KEYS)).subscribe((translations: object) => {
				this.setColumnDefs(translations);
			});
		});
		// grid must be ready
		this.settingsService.selectedTimeZone.subscribe(() => {
			if (this.gridOptions.api) {
				this.gridOptions.api.redrawRows();
			}
		});

		this.getRowNodeId = (data: IHistory) => {
			return data.datetime;
		};
	}

	setColumnDefs(translations: object): void {
		const context = this;
		this.columnDefs = [
			{
				headerName: translations[this.HEADER_KEYS.date],
				field: 'datetime',
				width: 200,
				sort: 'desc',
				valueFormatter: this.dateFormatter,
			},
			{
				headerName: translations[this.HEADER_KEYS.lodbId],
				field: 'lodb_order',
				width: 130,
				cellRenderer: this.urlCellRenderer,
				enableRowGroup: true,
				onCellClicked: (params) => context.router.navigate(['order/detail/', params.value]),
			},
			{
				headerName: translations[this.HEADER_KEYS.bookmark],
				field: 'isBookmark',
				width: 200,
				editable: true,
				onCellValueChanged: (event) => this.cellValueChanged(event),
				cellRenderer: this.cellRenderer,
				cellEditorFramework: BookmarkEditorComponent,
			},
		];
	}

	cellValueChanged(event) {
		if (!event || event.oldValue === event.newValue) {
			return;
		}
		const lodbOrderId = event.data.lodb_order;
		const text = event.newValue;

		if (!StringUtil.isEmpty(event.oldValue) && StringUtil.isEmpty(event.newValue)) {
			this.deleteBookmark.emit(lodbOrderId);
		} else if (!StringUtil.isEmpty(event.oldValue) && !StringUtil.isEmpty(event.newValue)) {
			this.updateBookmark.emit({ lodbOrderId: lodbOrderId, text: text });
		} else if (StringUtil.isEmpty(event.oldValue) && !StringUtil.isEmpty(event.newValue)) {
			this.createBookmark.emit({ lodbOrderId: lodbOrderId, text: text });
		}
	}

	cellRenderer(params) {
		const element = document.createElement('span');

		if (params.value && params.value.trim().length > 0) {
			let html = `<img src="assets/img/star.svg" width="15" style="vertical-align: top; margin-top: 6px">`;
			if (params.value !== 'x') {
				html += `<span style="margin-left: 8px">` + params.value + `</span>`;
			}
			element.innerHTML = html;
		}

		return element;
	}

	deleteData(): void {}
}
