<div *ngFor="let n of news" class="row news-box">
	<div class="col px-3 py-2">
		{{ n.news_text }}
		<small class="text-muted" style="display: block">{{ n.datetime | visDate }}</small>
	</div>
	<div class="px-0" style="width: 80px">
		<div class="news-type">
			<i
				*ngIf="n.news_type === FEATURE"
				[matTooltip]="'NEWS.feature' | translate"
				class="fa fa-puzzle-piece"
				aria-hidden="true"
			></i>
			<i
				*ngIf="n.news_type === BUGFIX"
				[matTooltip]="'NEWS.bugfix' | translate"
				class="fa fa-bug"
				aria-hidden="true"
			></i>
			<i
				*ngIf="n.news_type === INFO"
				[matTooltip]="'NEWS.info' | translate"
				class="fa fa-info"
				aria-hidden="true"
			></i>
		</div>
	</div>
</div>

<div class="row news-box" *ngIf="news && news.length === 0">
	<div class="col px-3 py-2">
		{{ 'NEWS.noNewsAvailable' | translate }}
		<small class="text-muted" style="display: block">{{ date | visDate }}</small>
	</div>
	<div class="px-0" style="width: 80px">
		<div class="news-type">
			<i [matTooltip]="'NEWS.info' | translate" class="fa fa-info" aria-hidden="true"></i>
		</div>
	</div>
</div>
