import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from './news.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NewsService } from './news.service';
import { NewsListComponent } from './news-list/news-list.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [
		CommonModule,
		MatProgressBarModule,
		TranslateModule.forChild(),
		MatTooltipModule,
		SharedModule,
	],
	declarations: [NewsComponent, NewsListComponent],
	providers: [NewsService],
	exports: [NewsComponent],
})
export class NewsModule {}
