export class StringUtil {
	/*
  will evaluate to true if value is not:
  null
  undefined
  NaN
  empty string ("")
  0
  false
  */
	public static isEmpty(value) {
		if (value) {
			return false;
		} else {
			return true;
		}
	}

	public static isNotEmpty(value) {
		return !StringUtil.isEmpty(value);
	}
}
