import { Action } from '@ngrx/store';

export const NOTIFY_SUCCESS = '[Notify] NOTIFY_SUCCESS';
export const NOTIFY_ERROR = '[Notify] NOTIFY_ERROR';

export class NotifySuccess implements Action {
	readonly type = NOTIFY_SUCCESS;

	constructor(public payload: { title: string; content: string }) {}
}

export class NotifyError implements Action {
	readonly type = NOTIFY_ERROR;

	constructor(public payload: { title: string; content: string }) {}
}

export type All = NotifySuccess | NotifyError;
