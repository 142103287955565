<div id="accordionSH" role="tablist" aria-multiselectable="true">
	<div class="card">
		<div class="card-loader-container">
			<mat-progress-bar
				*ngIf="showLoader"
				mode="indeterminate"
				[value]=""
				class="card-loader"
			></mat-progress-bar>
		</div>
		<div class="card-header">
			<ul class="nav nav-pills tab-header" role="tablist">
				<li class="nav-item">
					<a
						class="nav-link tab-header-pd active"
						data-toggle="tab"
						role="tab"
						href="#collapseSH1"
						(click)="setTab(tabHistory)"
					>
						<i class="fa fa-history" aria-hidden="true"></i>
						{{ 'SEARCH-HISTORY.headingHistory' | translate }}
					</a>
				</li>
				<li class="nav-item">
					<a
						class="nav-link tab-header-pd"
						data-toggle="tab"
						role="tab"
						href="#collapseSH2"
						(click)="setTab(tabBookmark)"
					>
						<i class="fa fa-star" aria-hidden="true"></i>
						{{ 'SEARCH-HISTORY.headingBookmarks' | translate }}
					</a>
				</li>
			</ul>

			<div class="float-right tab-header-pd">
				<i
					class="fa fa-trash mr-3"
					aria-hidden="true"
					matTooltip="{{ 'COMMON.deleteCols' | translate }}"
					(click)="deleteData()"
					*ngIf="activeTab === tabBookmark"
				></i>
				<i
					class="fa fa-th-large mr-3"
					aria-hidden="true"
					matTooltip="{{ 'COMMON.sizeCols' | translate }}"
					(click)="sizeCols()"
				></i>
				<i
					class="fa fa-refresh mr-3"
					aria-hidden="true"
					matTooltip="{{ 'COMMON.reloadData' | translate }}"
					(click)="loadData(true)"
				></i>

				<i
					*ngIf="activeTab === tabHistory"
					class="fa fa-info"
					aria-hidden="true"
					matTooltip="{{ 'SEARCH-HISTORY.infoHistory' | translate }}"
				></i>
				<i
					*ngIf="activeTab === tabBookmark"
					class="fa fa-info"
					aria-hidden="true"
					matTooltip="{{ 'SEARCH-HISTORY.infoBookmarks' | translate }}"
				></i>
			</div>
		</div>

		<div class="tab-content">
			<div id="collapseSH1" role="tabpanel" class="tab-pane fade in active show">
				<loi-history-table
					[rowData]="historyData$ | async"
					(createBookmark)="createBookmark($event)"
					(deleteBookmark)="deleteBookmark($event)"
					(updateBookmark)="updateBookmark($event)"
				></loi-history-table>
			</div>

			<div id="collapseSH2" role="tabpanel" class="tab-pane fade">
				<loi-bookmark-table
					[rowData]="bookmarkData$ | async"
					(deleteBookmark)="deleteBookmark($event)"
					(updateBookmark)="updateBookmark($event)"
				></loi-bookmark-table>
			</div>
		</div>
	</div>
</div>
