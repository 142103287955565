import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './protected/home/home.component';
import { WelcomeComponent } from './public/welcome/welcome.component';
import { AuthGuard } from './core/authentication/auth.guard';
import { ErrorComponent } from './public/error/error.component';
import { ErrorGuard } from './shared/guards/error.guard';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'home' },
	{ path: 'home', component: HomeComponent, canActivate: [AuthGuard, ErrorGuard] },
	{ path: 'welcome', component: WelcomeComponent, canActivate: [ErrorGuard] },
	{ path: 'error', component: ErrorComponent },
	{
		path: 'user',
		loadChildren: () => import('./protected/user/user.module').then((m) => m.UserModule),
		canActivate: [AuthGuard, ErrorGuard],
		// resolve: { groups: GroupsResolve }
	},
	{
		path: 'order',
		loadChildren: () => import('./protected/order/order.module').then((m) => m.OrderModule),
		// resolve: { groups: GroupsResolve },
		canActivate: [AuthGuard, ErrorGuard],
	},
	{ path: '**', redirectTo: 'welcome' },
];
export const AppRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
