import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'loi-order-search-dialog',
	template: `
		<form (submit)="onSearch(formDialogSearch)" novalidate #formDialogSearch="ngForm">
			<div class="row">
				<div class="col mr-2" style="width: 100px">
					<mat-form-field class="full-width">
						<input
							matInput
							placeholder="{{ 'FIELDS.anyOrderId' | translate }}"
							name="orderId"
							[(ngModel)]="data.id"
							minlength="4"
							required
							autofocus
						/>
						<mat-error>
							<loi-show-error text="Order ID" path="orderId"></loi-show-error>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="mr-2">
					<button
						type="submit"
						mat-raised-button
						color="primary"
						class="full-width"
						[disabled]="!formDialogSearch.form.valid"
					>
						{{ 'SEARCH-QUICK.btnSearch' | translate }}
					</button>
				</div>
			</div>
		</form>
	`,
})
export class SearchDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<SearchDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	onSearch(form: NgForm): void {
		this.data.isSearch = form.valid ? true : false;
		this.dialogRef.close(this.data);
	}
}
