import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BookmarkService } from '../services/bookmark.service';
import { of, forkJoin } from 'rxjs';
import { HistoryService } from '../services/history.service';

import * as historyActions from './history.action';
import { History } from '../model/history.model';
import * as bookmarkActions from './bookmark.action';
import * as notifyActions from '../../../core/notification/notify.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../core/authentication/auth.service';

@Injectable()
export class HistoryEffects {
	httpErrors$ = createEffect(() =>
		this.actions$.pipe(ofType(historyActions.GET_ALL_ERROR)).pipe(
			map((action: any) => action.payload),
			switchMap((error: any) => {
				return of(new notifyActions.NotifyError({ title: error.title, content: error.title }));
			})
		)
	);

	loadHistory$ = createEffect(() =>
		this.actions$.pipe(ofType(historyActions.GET_ALL)).pipe(
			map((action: historyActions.GetAll) => action.forceReload),
			switchMap((forceReload) => {
				const obsBookmark = this.bookmarkService.loadBookmarks(forceReload);
				const obsHistory = this.historyService.loadHistory(forceReload);

				return forkJoin(obsBookmark, obsHistory).pipe(
					map((data) => {
						const bookmarks = data[0];
						const history = data[1];

						if (history == null) {
							return { bookmarks: bookmarks, history: [] };
						}
						const h = history.map((entry) => {
							const b = bookmarks.filter((bookmark) => bookmark.lodbOrder === entry.lodb_order);
							const text = b.length === 1 ? b[0].text : null;
							return new History(entry, text);
						});

						return { bookmarks: bookmarks, history: h };
					}),
					switchMap((data) => [
						new bookmarkActions.GetAllSuccess(data.bookmarks),
						new historyActions.GetAllSuccess(data.history),
					]),
					catchError((error) =>
						of(
							new historyActions.GetAllError({
								error: error,
								title: 'NOTIFICATION.searchHistory',
								content: 'NOTIFICATION.errorData',
							})
						)
					)
				);
			})
		)
	);

	persistHistory$ = createEffect(() =>
		this.actions$.pipe(ofType(historyActions.CREATE_ONE)).pipe(
			map((action: historyActions.CreateOne) => action.id),
			switchMap((id) => {
				const searchHistoryEntry = new History();
				searchHistoryEntry.lodb_order = id;
				searchHistoryEntry.datetime = new Date().toISOString();
				searchHistoryEntry.user = this.authService.getUserInfo().eMail;

				return this.historyService.persistSearch(searchHistoryEntry).pipe(
					map(() => new historyActions.CreateOneSuccess(searchHistoryEntry)),
					catchError((error) => of(new historyActions.CreateOneError(error)))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private historyService: HistoryService,
		private bookmarkService: BookmarkService,
		private authService: AuthService
	) {}
}
