export interface IHistory {
	user: string;
	datetime: string;
	lodb_order: number;
	isBookmark: string;
}

export class History implements IHistory {
	user: string;
	datetime: string;
	lodb_order: number;
	isBookmark: string;

	constructor(data?: IHistory, isBookmark?: string) {
		this.user = (data && data.user) || null;
		this.datetime = (data && data.datetime) || null;
		this.lodb_order = (data && data.lodb_order) || null;
		this.isBookmark = isBookmark || null;
	}
}

export function generateMockHistory(): IHistory {
	return {
		user: 'viaott',
		datetime: '2017-01-01 00:00:00.0',
		lodb_order: 111,
		isBookmark: null,
	};
}
