import { GridOptions } from 'ag-grid';
import { CommonUtil } from '../../core/util/common.util';

export abstract class SearchTableComponent {
	gridOptions: GridOptions;

	constructor() {}

	dateFormatter(params: { value: string }) {
		return CommonUtil.formatDate(params.value);
	}

	urlCellRenderer(params: { value: any }) {
		return `<span style="cursor: default;"><a href="javascript:void(0)">${params.value}</a></span>`;
	}

	sizeCols(): void {
		if (this.gridOptions.api) {
			this.gridOptions.api.sizeColumnsToFit();
		}
	}

	abstract deleteData(): void;
}
