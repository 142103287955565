import { Component, OnInit, ViewChild } from '@angular/core';
import { BookmarkTableComponent } from './search-bookmark-table/bookmark-table.component';
import { HistoryTableComponent } from './search-history-table/history-table.component';
import { SearchTableComponent } from './search-table.component';
import { BookmarkService } from './services/bookmark.service';
import { HistoryService } from './services/history.service';
import { Store } from '@ngrx/store';
import * as fromOrderSearchHistory from './store';
import * as HistoryActions from './store/history.action';
import * as BookmarkActions from './store/bookmark.action';
import { IHistory } from './model/history.model';
import { combineLatest, Observable } from 'rxjs';
import { IBookmark } from './model/bookmark.model';
import { map } from 'rxjs/operators';

@Component({
	selector: 'loi-order-search-history',
	templateUrl: './order-search-history.component.html',
	styleUrls: ['./order-search-history.component.scss'],
})
export class OrderSearchHistoryComponent implements OnInit {
	readonly tabHistory = 1;
	readonly tabBookmark = 2;
	activeTab = this.tabHistory;

	@ViewChild(BookmarkTableComponent) bookmarkTable: SearchTableComponent;
	@ViewChild(HistoryTableComponent) historyTable: SearchTableComponent;

	showLoader = false;

	historyData$: Observable<Array<IHistory>>;
	bookmarkData$: Observable<Array<IBookmark>>;

	constructor(
		private searchHistoryService: HistoryService,
		private searchBookmarkService: BookmarkService,
		private store$: Store<fromOrderSearchHistory.State>
	) {
		this.historyData$ = store$.select(fromOrderSearchHistory.getAllHistoryEntries);
		this.bookmarkData$ = store$.select(fromOrderSearchHistory.getAllBookmarks);

		combineLatest([
			this.store$.select(fromOrderSearchHistory.getBookmarkLoadingState),
			this.store$.select(fromOrderSearchHistory.getHistoryLoadingState),
		])
			.pipe(map((results: boolean[]) => results[0] || results[1]))
			.subscribe((data) => {
				this.showLoader = data;
			});
	}

	ngOnInit() {
		this.showLoader = true;
		this.store$.dispatch(new HistoryActions.GetAll(false));
	}

	setTab(tab: number) {
		this.activeTab = tab;
		setTimeout(() => {
			this.sizeCols();
		}, 100);
	}

	sizeCols() {
		this.getActiveViewChild().sizeCols();
	}

	loadData(forceReload = false) {
		this.showLoader = true;

		if (this.isHistoryTabActive()) {
			this.store$.dispatch(new HistoryActions.GetAll(forceReload));
		} else {
			this.store$.dispatch(new BookmarkActions.GetAll(forceReload));
		}
	}

	deleteData() {
		this.getActiveViewChild().deleteData();
	}

	createBookmark(b: { lodbOrderId: number; text: string }) {
		this.store$.dispatch(new BookmarkActions.CreateOne(b));
	}

	deleteBookmark(lodbOrderId: number) {
		this.store$.dispatch(new BookmarkActions.DeleteOne(lodbOrderId));
	}

	updateBookmark(b: { lodbOrderId: number; text: string }) {
		this.store$.dispatch(
			new BookmarkActions.UpdateOne({ id: b.lodbOrderId, changes: { text: b.text } })
		);
	}

	private getActiveViewChild(): SearchTableComponent {
		if (this.isHistoryTabActive()) {
			return this.historyTable;
		} else {
			return this.bookmarkTable;
		}
	}

	private isHistoryTabActive(): boolean {
		return this.activeTab === this.tabHistory;
	}
}
