import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromBookmark from './bookmark.reducer';
import * as fromHistory from './history.reducer';
import * as fromRoot from '../../../index';
import { IBookmark } from '../model/bookmark.model';

export interface OrderSearchHistoryState {
	bookmarks: fromBookmark.State;
	history: fromHistory.State;
}

export interface State extends fromRoot.State {
	orderSearchHistory: OrderSearchHistoryState;
}

export const reducers = {
	bookmarks: fromBookmark.reducer,
	history: fromHistory.reducer,
};

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getOrderSearchHistoryState =
	createFeatureSelector<OrderSearchHistoryState>('orderSearchHistory');

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */
export const getBookmarkState = createSelector(
	getOrderSearchHistoryState,
	(state) => state.bookmarks
);
export const getHistoryState = createSelector(getOrderSearchHistoryState, (state) => state.history);

/*
 * BOOKMARK SELECTORS
 */

// select the array of bookmarks
export const getAllBookmarks = createSelector(getBookmarkState, fromBookmark.getEntries);
// select the dictionary of bookmarks entities
export const getBookmarkEntities = createSelector(getBookmarkState, fromBookmark.getMapEntries);
export const getAllBookmarkIds = createSelector(getBookmarkState, fromBookmark.getIds);
export const getBookmarkLoadingState = createSelector(getBookmarkState, fromBookmark.getLoading);

export const hasBookmarkInStore = (lodbOrderId: number) =>
	createSelector(getBookmarkEntities, (entries: IBookmark[]) => !!entries[lodbOrderId]);

/*
 * HISTORY SELECTORS
 */
export const getAllHistoryEntries = createSelector(getHistoryState, fromHistory.getEntries);
export const getHistoryIds = createSelector(getHistoryState, fromHistory.getIds);
export const getHistoryLoadingState = createSelector(getHistoryState, fromHistory.getLoading);
