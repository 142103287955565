export interface IBookmark {
	user: string;
	dateTime: string;
	lodbOrder: number;
	text: string;
}

export class Bookmark implements IBookmark {
	text: string;
	user: string;
	dateTime: string;
	lodbOrder: number;

	constructor(data?: IBookmark) {
		this.user = (data && data.user) || null;
		this.dateTime = (data && data.dateTime) || null;
		this.lodbOrder = (data && data.lodbOrder) || null;
		this.text = (data && data.text) || null;
	}
}

export function generateMockBookmark(): IBookmark {
	return {
		user: 'viaott',
		dateTime: '2017-09-12 00:00:00.0',
		lodbOrder: 111,
		text: 'x',
	};
}
