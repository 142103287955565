import { formatDate } from '@angular/common';
import { Inject, InjectionToken, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { Subject, takeUntil } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ILanguage, languages } from '../../core/settings/settings.service';

export type DateFormat = 'full' | 'short' | 'day' | 'month' | 'year' | 'time' | 'dateonly' | string;
export const LOCAL_LANG = new InjectionToken<BehaviorSubject<ILanguage>>('LOCAL_LANG');

@Pipe({
	name: 'visDate',
	pure: false,
})
export class DatePipe implements PipeTransform, OnDestroy {
	private locale?: BehaviorSubject<ILanguage>;
	private destroy$ = new Subject<void>();
	private args: { value?: Date | dayjs.Dayjs | string | null | undefined; format?: DateFormat } =
		{};

	constructor(@Inject(LOCAL_LANG) locale: BehaviorSubject<ILanguage>) {
		this.locale = locale;
		this.locale
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.transform(this.args.value, this.args.format));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	transform(value: Date | dayjs.Dayjs | string | null | undefined, format: DateFormat = 'full') {
		this.args.value = value;
		this.args.format = format;

		if (!value) {
			return undefined;
		}

		if (format.length < 1) {
			format = 'full';
		}

		if (!this.locale) {
			const fallbackLang = languages[0];
			console.warn(`DatePipe: No locale provided. Falling back to ${fallbackLang.name}.`);
			this.locale = new BehaviorSubject<ILanguage>(fallbackLang);
		}

		const normalizedValue = dayjs(value);

		const locale = (this.locale.value?.dayjsCode ?? this.locale.value.name).toLowerCase();
		let retval: string | null = null;
		switch (format) {
			case 'full':
				retval =
					formatDate(normalizedValue.toDate(), 'mediumDate', locale) +
					', ' +
					formatDate(normalizedValue.toDate(), 'mediumTime', locale);
				break;
			case 'short':
				retval =
					formatDate(normalizedValue.toDate(), 'shortDate', locale) +
					', ' +
					formatDate(normalizedValue.toDate(), 'shortTime', locale);
				break;
			case 'day':
				retval = formatDate(normalizedValue.toDate(), 'shortDate', locale);
				break;
			case 'month':
				retval = formatDate(normalizedValue.toDate(), 'M/YYYY', locale);
				break;
			case 'year':
				retval = formatDate(normalizedValue.toDate(), 'YYYY', locale);
				break;
			case 'time':
				retval = formatDate(normalizedValue.toDate(), 'mediumTime', locale);
				break;
			case 'dateonly':
				retval = formatDate(normalizedValue.toDate(), 'mediumDate', locale);
				break;
			default:
				retval = formatDate(normalizedValue.toDate(), format, locale);
				break;
		}

		return retval ?? normalizedValue;
	}
}
